import ALL_USERS from '@/graphql/user/query/getAllUsersAutocomplete.gql'

const users = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function usersAutocomplete(params: any) {
  const apolloQuery = ALL_USERS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'FIRST_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchUsersCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await usersAutocomplete({ search: queryString })
    cb(results?.getAllUsers?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchUsersCb')
  }
}

export function getSearchUsers(entityUser: any, entityUsers: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchUsers('', entityUser, entityUsers)
  }

  if (entityUser && !users?.value?.find((item) => item.value === entityUser?.uuid)) {
    users.value.push({
      label: `${entityUser.first_name ?? ''} ${entityUser.last_name ?? ''}`,
      value: entityUser.uuid
    })
  }

  return users?.value.filter(i => i.label) ?? []
}

export async function searchUsers(queryString: any, entityUser: any, entityUsers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await usersAutocomplete({ search: queryString })
    const remoteUsers = results?.getAllUsers?.data?.map((i: any) => {
      return {
        label: `${i.first_name ?? ''} ${i.last_name ?? ''}`,
        value: i.uuid
      }
    }) ?? []
    const usersToAdd: { label: any; value: any }[] = []
    entityUsers?.forEach((j: any) => {
      if (!remoteUsers.find((k: any) => k.value === j.uuid)) {
        usersToAdd.push({
          label: `${j.first_name ?? ''} ${j.last_name ?? ''}`,
          value: j.uuid
        })
      }
    })
    if (entityUser && !remoteUsers.find((k: any) => k.value === entityUser.uuid)) {
      usersToAdd.push({
        label: `${entityUser.first_name ?? ''} ${entityUser.last_name ?? ''}`,
        value: entityUser.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    users.value = [...remoteUsers, ...usersToAdd]

    firstTime.value = false

    if (entityUser) {
      getSearchUsers(entityUser, entityUsers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchUsers')
  }
}

export function getUserKey () {
  return key.value
}

export function initialUsers () {
  users.value = []
  key.value = 0
  firstTime.value = true
}