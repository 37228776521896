import revive_payload_client_4sVQNw7RlN from "/__w/backoffice/backoffice/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/__w/backoffice/backoffice/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/__w/backoffice/backoffice/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/__w/backoffice/backoffice/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/__w/backoffice/backoffice/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/__w/backoffice/backoffice/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/__w/backoffice/backoffice/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/__w/backoffice/backoffice/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/__w/backoffice/backoffice/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import apollo_client_W5VimTV2fJ from "/__w/backoffice/backoffice/plugins/apollo-client.js";
import common_components_do797zPtCk from "/__w/backoffice/backoffice/plugins/common-components.js";
import element_plus_elM4AXquKi from "/__w/backoffice/backoffice/plugins/element-plus.js";
import error_notification_FF7BOyH8zx from "/__w/backoffice/backoffice/plugins/error-notification.js";
import event_bus_JNcXIfrhYt from "/__w/backoffice/backoffice/plugins/event-bus.js";
import firebase_Tc1lVUbp7M from "/__w/backoffice/backoffice/plugins/firebase.js";
import google_maps_isOUeleMUR from "/__w/backoffice/backoffice/plugins/google-maps.js";
import i18n_sVHQBgnb3t from "/__w/backoffice/backoffice/plugins/i18n.js";
import sentry_PZVGm6LH3G from "/__w/backoffice/backoffice/plugins/sentry.js";
import success_notification_wwqWDNZ25g from "/__w/backoffice/backoffice/plugins/success-notification.js";
import vue_apple_login_t4H57VMhxS from "/__w/backoffice/backoffice/plugins/vue-apple-login.js";
import vue_cookies_RVVac5KJy2 from "/__w/backoffice/backoffice/plugins/vue-cookies.js";
import vue_facebook_login_8zsQURjR1L from "/__w/backoffice/backoffice/plugins/vue-facebook-login.js";
import vue_google_login_WYxqbhB9mr from "/__w/backoffice/backoffice/plugins/vue-google-login.js";
import vue_quill_LIGcPOErAe from "/__w/backoffice/backoffice/plugins/vue-quill.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  apollo_client_W5VimTV2fJ,
  common_components_do797zPtCk,
  element_plus_elM4AXquKi,
  error_notification_FF7BOyH8zx,
  event_bus_JNcXIfrhYt,
  firebase_Tc1lVUbp7M,
  google_maps_isOUeleMUR,
  i18n_sVHQBgnb3t,
  sentry_PZVGm6LH3G,
  success_notification_wwqWDNZ25g,
  vue_apple_login_t4H57VMhxS,
  vue_cookies_RVVac5KJy2,
  vue_facebook_login_8zsQURjR1L,
  vue_google_login_WYxqbhB9mr,
  vue_quill_LIGcPOErAe
]