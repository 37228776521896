<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              v-if="!props.invoice?.uuid"
            >
              <ElFormItem
                prop="invoice_uuid"
                :label="$t('invoice')"
                class="autocomplete-new-entity"
                :class="form.invoice_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getInvoiceKey()"
                  v-loading="getInvoiceKey() === 0"
                  v-model="form.invoice_uuid"
                  :remote-method="searchInvoices"
                  :placeholder="$t('select_an_invoice')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchInvoices()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewInvoiceDialog = true"
                />
                <Icons
                  v-if="form.invoice_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/finances/invoices', {
                      uuid: form.invoice_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="payment_method_uuid"
                :label="$t('payment_method')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getPaymentMethodKey()"
                  v-loading="getPaymentMethodKey() === 0"
                  v-model="form.payment_method_uuid"
                  :remote-method="searchPaymentMethods"
                  :placeholder="$t('select_an_option')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchPaymentMethods()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewPaymentMethodDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="amount" :label="$t('amount')">
                <ElInputNumber
                  v-model="form.amount"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="status" :label="$t('status')">
                <ElSelect
                  v-model="form.status"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="date" :label="$t('date')">
                <ElDatePicker
                  v-model="form.date"
                  class="!w-full"
                  type="datetime"
                  format="DD/MM/YYYY HH:mm"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                  @change="form.date = fullFormatDateRaw($event)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="real_payment_type"
                :label="$t('real_payment_type')"
              >
                <ElSelect
                  v-model="form.real_payment_type"
                  :placeholder="$t('select_a_type')"
                  style="width: 100%"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <el-option
                    v-for="item in realPaymentTypes"
                    :key="item"
                    :label="$t(item.toLowerCase())"
                    :value="item"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="real_payment_uuid"
                :label="$t('credit_card_payment')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getCreditCardPaymentKey()"
                  v-loading="getCreditCardPaymentKey() === 0"
                  v-model="form.real_payment_uuid"
                  :remote-method="searchCreditCardPayments"
                  :placeholder="$t('select_a_credit_card_payment')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCreditCardPayments()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewCreditCardPaymentDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              v-if="width > 1100 && !props.invoice?.uuid"
            />
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewInvoiceDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_invoice') }}
        </span>
      </template>
      <QuickCreateInvoiceDialog
        @create=";(showNewInvoiceDialog = false), createInvoice($event)"
        @cancel="showNewInvoiceDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCreditCardPaymentDialog"
      :width="containerWidth <= 600 ? '100%' : '400px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_credit_card_payment') }}
        </span>
      </template>
      <QuickCreateCreditCardPaymentDialog
        @create="
          ;(showNewCreditCardPaymentDialog = false),
            createCreditCardPayment($event)
        "
        @cancel="showNewCreditCardPaymentDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewPaymentMethodDialog"
      :width="containerWidth <= 600 ? '100%' : '400px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_payment_method') }}
        </span>
      </template>
      <QuickCreatePaymentMethodDialog
        @create="
          ;(showNewPaymentMethodDialog = false), createPaymentMethod($event)
        "
        @cancel="showNewPaymentMethodDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_PAYMENT from '~/graphql/payments/mutation/createPayment.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  invoice: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  invoice_uuid: props.invoice?.uuid ?? '',
  payment_method_uuid: '',
  amount: 0,
  date: '',
  status: '',
  issuer_uuid: '',
  real_payment_type: 'CreditCardPayment',
  real_payment_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const showNewCreditCardPaymentDialog = ref(false)
const showNewPaymentMethodDialog = ref(false)
const showNewInvoiceDialog = ref(false)
const statuses = getPaymentStatus()

const rules = {
  invoice_uuid: { required },
  payment_method_uuid: { required },
  amount: { required },
  date: { required },
  status: { required },
  real_payment_type: { required },
  real_payment_uuid: { required }
}

onMounted(() => {
  initialCreditCardPayments()
  initialPaymentMethods()
  initialInvoices()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  invoice_uuid: [
    {
      required: true,
      message: t('invoice_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  payment_method_uuid: [
    {
      required: true,
      message: t('payment_method_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  amount: [
    {
      required: true,
      message: t('amount_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  date: [
    {
      required: true,
      message: t('date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  status: [
    {
      required: true,
      message: t('status_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  real_payment_type: [
    {
      required: true,
      message: t('real_payment_type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  real_payment_uuid: [
    {
      required: true,
      message: t('real_payment_uuid_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const realPaymentTypes = computed(() => {
  return ['CreditCardPayment']
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_PAYMENT, {
    payment_method_uuid: form.payment_method_uuid,
    amount: form.amount
      ? parseFloat(parseFloat(form.amount * 100).toFixed(2))
      : 0,
    status: form.status,
    date: form.date,
    real_payment_type: `App\\Models\\${form.real_payment_type}`,
    real_payment_uuid: form.real_payment_uuid,
    invoice_uuid: form.invoice_uuid
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createPayment ?? null)
    loading.value = false
  }
}

const createCreditCardPayment = (event) => {
  if (event?.uuid) {
    form.real_payment_uuid = event.uuid
    form.realPayment = event
    searchCreditCardPayments(event.amount, form.realPayment, null, true)
  }
}

const createPaymentMethod = (event) => {
  if (event?.uuid) {
    form.payment_method_uuid = event.uuid
    form.paymentMethod = event
    searchPaymentMethods(event.number, form.paymentMethod, null, true)
  }
}

const createInvoice = (event) => {
  if (event?.uuid) {
    form.invoice_uuid = event.uuid
    form.invoice = event
    searchInvoices(event.number, form.invoice, null, true)
  }
}
</script>
