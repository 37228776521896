<template>
  <div class="module-selector-container">
    <div
      v-for="(item, index) in modules"
      :key="index"
      class="module-item"
      @click="goTo(item), $emit('setModule', item.title)"
      @contextmenu="newWindowItem(props.item)"
    >
      <div class="icon">
        <Icons :name="`Backoffice/${item.icon}`" />
      </div>
      <div class="text">
        <div class="text-content label">
          {{ t(item.title) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import verticalSidebarItemsBackoffice from '~/models/vertical-sidebar-items-backoffice'
import verticalSidebarItemsGuide from '~/models/vertical-sidebar-items-guide'
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'

const { t } = useI18n()
const { width, height } = useBreakpoints()
const router = useRouter()
const roles = getAuthRoles()
const user = getAuthUser()

const modules = computed(() => {
  let verticalSidebarItems = verticalSidebarItemsBackoffice
  if (!user.value?.employee) {
    verticalSidebarItems = verticalSidebarItems.filter(
      (i) => i.group !== '/profile'
    )
  }
  if (roles.value?.find((i) => i.name === 'Guide')) {
    verticalSidebarItems = verticalSidebarItemsGuide
  }
  return verticalSidebarItems.filter((item) => {
    if (width.value > 1100) {
      return (
        item.icon &&
        item.title !== 'home' &&
        (!item.roles ||
          item.roles.length === 0 ||
          (item.roles && item.roles.length))
      )
    } else {
      return (
        item.icon &&
        (!item.roles ||
          item.roles.length === 0 ||
          (item.roles && item.roles.length))
      )
    }
  })
})

const goTo = (item) => {
  if (!item.group && item.to) {
    router.push(item.to)
  }
  if (item.group && item.children?.length && item.children[0].to) {
    router.push(`${item.group}/${item.children[0].to}`)
  }
  if (item.group === '/') {
    router.push('/')
  }
}
const newWindowItem = (item) => {
  if (!item.group && item.to) {
    window.open(item.to, '_blank')
  }
  if (item.group && item.children?.length && item.children[0].to) {
    window.open(`${item.group}/${item.children[0].to}`, '_blank')
  }
  if (item.group === '/') {
    window.open('/', '_blank')
  }
}
</script>

<style scoped lang="scss">
.module-selector-container {
  margin-top: 32px;
  .module-item {
    cursor: pointer;
    width: 100% !important;
    height: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    div.icon {
      text-align: center !important;
      height: 24px !important;
      display: inline-block;
      margin-right: 16px;
      i {
        width: 24px !important;
        height: 24px !important;
        display: block !important;
      }
    }
    div.text {
      color: var(--brand-off-black) !important;
      height: 24px !important;
      display: inline-block;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      .text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap !important;
      }
    }
  }
}
</style>