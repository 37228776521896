import { useI18n } from 'vue-i18n';
import { locationSubtypes } from '~/models/location-subtypes';

export const getLocationSubtypes = () => {
    const { t } = useI18n();
    return locationSubtypes.map((subtype) => {
        return {
            label: t(subtype.name.toLowerCase()),
            value: subtype.name
        };
    });
}
