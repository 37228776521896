export const invoiceTypes = {
  colors: {
    sale: 'primary',
    purchase: 'primary',
    credit: 'primary'
  },
  types: [
    'sale',
    'purchase',
    'credit'
  ]
}
