import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'
import fr from 'element-plus/es/locale/lang/fr'
import de from 'element-plus/es/locale/lang/de'
import sv from 'element-plus/es/locale/lang/sv'
import ar from 'element-plus/es/locale/lang/ar'

export default defineNuxtPlugin((nuxtApp) => {
  let language = en
  switch (navigator.language) {
    case 'es':
      language = es
      break
    case 'en':
      language = en
      break
    case 'fr':
      language = fr
      break
    case 'de':
      language = de
      break
    case 'sv':
      language = sv
      break
    case 'ar':
      language = ar
      break
    default:
      language = en
      break
  }
  nuxtApp.vueApp.use(ElementPlus, {
    locale: es
  })

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    nuxtApp.vueApp.component(key, component)
  }
})
