import ALL_PAYMENT_METHODS from '@/graphql/payment-methods/query/getAllPaymentMethods.gql'

const paymentMethods = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export function paymentMethodsAutocomplete(params: any) {
  const apolloQuery = ALL_PAYMENT_METHODS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'NUMBER',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchPaymentMethodsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await paymentMethodsAutocomplete({ search: queryString })
    cb(results?.getAllPaymentMethods?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPaymentMethodsCb')
  }
}

export function getSearchPaymentMethods(entityPaymentMethod: any, entityPaymentMethods: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchPaymentMethods('', entityPaymentMethod, entityPaymentMethods)
  }

  if (entityPaymentMethod && !paymentMethods?.value?.find((item) => item.value === entityPaymentMethod?.uuid)) {
    paymentMethods.value.push({
      label: `${entityPaymentMethod.method} - ${entityPaymentMethod.number}` ?? '',
      value: entityPaymentMethod.uuid
    })
  }

  return paymentMethods.value
}

export async function searchPaymentMethods(queryString: any, entityPaymentMethod: any, entityPaymentMethods: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await paymentMethodsAutocomplete({ search: queryString })
    const remotePaymentMethods = results?.getAllPaymentMethods?.data?.map((i: any) => {
      return {
        label: `${i.method} - ${i.number}` ?? '',
        value: i.uuid
      }
    }) ?? []
    const paymentMethodsToAdd: { label: any; value: any }[] = []
    entityPaymentMethods?.forEach((j: any) => {
      if (!remotePaymentMethods.find((k: any) => k.value === j.uuid)) {
        paymentMethodsToAdd.push({
          label: `${j.method} - ${j.number}` ?? '',
          value: j.uuid
        })
      }
    })
    if (entityPaymentMethod && !remotePaymentMethods.find((k: any) => k.value === entityPaymentMethod.uuid)) {
      paymentMethodsToAdd.push({
        label: `${entityPaymentMethod.method} - ${entityPaymentMethod.number}` ?? '',
        value: entityPaymentMethod.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    paymentMethods.value = [...remotePaymentMethods, ...paymentMethodsToAdd]
    firstTime.value = false

    if (entityPaymentMethod) {
      getSearchPaymentMethods(entityPaymentMethod, entityPaymentMethods)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPaymentMethods')
  }
}

export function getPaymentMethodKey () {
  return key.value
}

export function initialPaymentMethods () {
  paymentMethods.value = []
  firstTime.value = true
  key.value = 0
}