export const weekDaysObject = {
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday'
}

export const weekDays = [
    weekDaysObject.monday.toUpperCase(),
    weekDaysObject.tuesday.toUpperCase(),
    weekDaysObject.wednesday.toUpperCase(),
    weekDaysObject.thursday.toUpperCase(),
    weekDaysObject.friday.toUpperCase(),
    weekDaysObject.saturday.toUpperCase(),
    weekDaysObject.sunday.toUpperCase()
]