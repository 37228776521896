import { useI18n } from 'vue-i18n'
import { currencies } from '@/models/currencies'

export function getCurrencies () {
  const { t } = useI18n()
  return currencies.map((currency: { value: any; label: any, symbol: any }) => {
    return {
      label: t(`currencies.${currency.label}`),
      value: currency.value.toLowerCase(),
      symbol: currency.symbol
    }
  })
}
