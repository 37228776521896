<template>
  <div
    v-if="props.item && props.item.clickable"
    class="module-item"
    :class="getSelectedModuleClass(props.item)"
    @click="goTo(props.item), $emit('setModule', props.item.title)"
    @contextmenu="newWindowItem(props.item)"
  >
    <div class="icon">
      <Icons :name="`Backoffice/${props.item.icon}`" />
    </div>
    <div class="text">
      <div class="text-content label">
        {{ t(props.item.title) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  item: {
    type: Object,
    required: false,
    default: () => {}
  },
  moduleSelected: {
    type: String,
    required: false,
    default: ''
  },
  isDropdown: {
    type: Boolean,
    required: false,
    default: false
  }
})

const getSelectedModuleClass = (item) => {
  if (props.moduleSelected && item.title === props.moduleSelected) {
    return 'selected'
  }
  return ''
}

const goTo = (item) => {
  if (!item.group && item.to) {
    router.push(item.to)
  }
  if (item.group && item.children?.length && item.children[0].to) {
    router.push(`${item.group}/${item.children[0].to}`)
  }
  if (item.group === '/') {
    router.push('/')
  }
}
const newWindowItem = (item) => {
  if (!item.group && item.to) {
    window.open(item.to, '_blank')
  }
  if (item.group && item.children?.length && item.children[0].to) {
    window.open(`${item.group}/${item.children[0].to}`, '_blank')
  }
  if (item.group === '/') {
    window.open('/', '_blank')
  }
}
</script>

<style scoped lang="scss">
.module-item {
  cursor: pointer;
  text-align: center !important;
  padding: 8px !important;
  width: 90px !important;
  height: 70px;
  background-color: #FBFBFB;
  border-radius: 8px;
  div.icon {
    text-align: center !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    i {
      width: 24px !important;
      height: 24px !important;
      display: block !important;
    }
  }
  div.text {
    color: var(--brand-off-black) !important;
    width: 78px !important;
    height: 16px !important;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    .text-content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap !important;
    }
  }
}
</style>