export const priceLevels = [
    {
        name: 'FREE'
    },
    {
        name: 'INEXPENSIVE'
    },
    {
        name: 'MODERATE'
    },
    {
        name: 'EXPENSIVE'
    },
    {
        name: 'VERY_EXPENSIVE'
    }
]
