import { useQuery } from '@vue/apollo-composable'

export function query(queryName: any, variables: any, fetchPolicy: any = null) {
  return new Promise((resolve, reject) => {
    const options =
      typeof fetchPolicy === 'string'
        ? { fetchPolicy, errorPolicy: 'all' }
        : typeof fetchPolicy === 'object' && fetchPolicy !== null
        ? { ...fetchPolicy, errorPolicy: 'all' }
        : { fetchPolicy: null, errorPolicy: 'all' }

    const { onResult, onError } = useQuery(queryName, variables, options)

    onResult(({ loading, errors, error, data }) => {
      if (!loading) {
        if (errors || error) {
          reject(errors || error)
        } else {
          resolve(data)
        }
      }
    })

    onError(({ graphQLErrors }) => reject(graphQLErrors))
  })
}