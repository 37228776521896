export const contractDismissalReason = [
    'volunteer_leaving',
    'contract_renewal',
    'conditions_change',
    'contract_end',
    'contract_extension',
    'disciplinary_dismissal',
    'objective_dismissal',
    'no_pass_trial_time',
    'anulation',
    'contract_suspension',
    'not_incorporated',
    'surrogacy'
]