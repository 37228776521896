import { useI18n } from 'vue-i18n'
import { paymentStatuses } from '@/models/payment-statuses'

export function getPaymentStatus () {
  const { t } = useI18n()
  return paymentStatuses.statuses.map((status: any) => {
    return {
      label: t(`payment_statuses.${status}`),
      value: status.toUpperCase()
    }
  })
}
