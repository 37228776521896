import { useI18n } from 'vue-i18n';
import { contractTypes } from '~/models/contract-types';

export const getContractTypes = () => {
  const { t } = useI18n();
  return contractTypes.map((item) => {
    return {
      label: t(item.toLowerCase()),
      value: item.toUpperCase()
    };
  });
}
