import ALL_PRODUCTS from '@/graphql/products/query/getAllProductsAutocomplete.gql'

const products = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function productsAutocomplete(params: any) {
  const apolloQuery = ALL_PRODUCTS
  const variables = {
    search: params.search ? params.search : null,
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchProductsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await productsAutocomplete({ search: queryString })
    cb(results?.getAllProducts?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchProductsCb')
  }
}

export function getSearchProducts(entityProduct: any, entityProducts: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchProducts('', entityProduct, entityProducts)
  }

  if (entityProduct && !products?.value?.find((item) => item.value === entityProduct?.uuid)) {
    products.value.push({
      label: entityProduct.name,
      value: entityProduct.uuid
    })
  }

  return products?.value ?? []
}

export async function searchProducts(queryString: any, entityProduct: any, entityProducts: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await productsAutocomplete({ search: queryString })
    const remoteProducts = results?.getAllProducts?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []
    const productsToAdd: { label: any; value: any }[] = []
    entityProducts?.forEach((j: any) => {
      if (!remoteProducts.find((k: any) => k.value === j.uuid)) {
        productsToAdd.push({
          label: j.name,
          value: j.uuid
        })
      }
    })
    if (entityProduct && !remoteProducts.find((k: any) => k.value === entityProduct.uuid)) {
      productsToAdd.push({
        label: entityProduct.name,
        value: entityProduct.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    products.value = [...remoteProducts, ...productsToAdd]
    firstTime.value = false

    if (entityProduct) {
      getSearchProducts(entityProduct, entityProducts)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchProducts')
  }
}

export function getProductKey () {
  return key.value
}

export function initialProducts () {
  products.value = []
  key.value = 0
  firstTime.value = true
}