import GET_PROVINCE_BY_NAME from '@/graphql/provinces/query/getProvinceByName.gql'

export function apolloProvincesByName(name: any) {
    return new Promise(async (resolve, reject) => {
        const result = await query(GET_PROVINCE_BY_NAME, { name }, { fetchPolicy: 'network-only' })

        resolve(result?.getProvinceByName ?? [])
    })
}

export async function searchProvincesByName(event: any) {
    if (event) {
        const provinces = await apolloProvincesByName(event)
        return provinces
    } else {
        return []
    }
}
