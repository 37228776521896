import { useI18n } from 'vue-i18n'
import { timezones } from '@/models/timezones'

export function getTimezones () {
  const { t } = useI18n()
  return timezones.map((timezone: any) => {
    return {
      label: timezone.replaceAll('_', ' '),
      value: timezone
    }
  })
}
