<template>
  <div class="custom-drawer" :class="className" v-if="drawer && width > 1100">
    <div class="custom-drawer-header">
      <Icons
        name="24px/chevron_left"
        v-if="showBack"
        class="icon-back"
        @click="$emit('back')"
      />
      <span :id="titleId" class="dashboard-title">
        {{ title }}
      </span>
      <Icons
        v-if="showClose"
        name="24px/close"
        class="icon-close"
        @click="$emit('close')"
      />
    </div>
    <div class="custom-drawer-scrollable">
      <slot />
    </div>
    <div class="custom-drawer-actions">
      <ElButton
        v-if="secondaryActionText"
        @click="$emit(secondaryAction)"
        type="secondary"
        class="reset-button"
      >
        {{ $t(secondaryActionText) }}
      </ElButton>
      <ElButton
        v-if="width > 1100 && primaryActionText"
        type="primary"
        @click="$emit(primaryAction)"
        class="accept-button"
        :class="!secondaryActionText ? 'full-width' : ''"
      >
        <Icons
          v-if="primaryActionIcon"
          :name="primaryActionIcon"
          class="mr-2"
        />{{ $t(primaryActionText) }}
      </ElButton>
    </div>
  </div>
  <el-drawer
    v-if="drawer && width <= 1100"
    ref="drawerRef"
    :model-value="drawer"
    direction="rtl"
    :close-on-press-escape="true"
    :close-on-click-modal="false"
    :show-close="showClose"
    modal-class="custom-drawer-overlay"
    class="custom-drawer"
    :class="`${className} ${
      secondaryActionText ||
      ((width > 1100 || (width <= 1100 && !secondaryActionText)) &&
        primaryActionText)
        ? 'with-actions'
        : ''
    }`"
    :size="width <= 1100 ? '100%' : '306px'"
    :z-index="width <= 1100 ? 1096 : 3"
  >
    <template #header="{}">
      <div>
        <Icons
          name="24px/chevron_left"
          v-if="showBack"
          class="icon-back"
          @click="$emit('close')"
        />
        <span :id="id" class="dashboard-title">
          {{ title }}
        </span>
        <Icons
          v-if="showClose"
          name="24px/close"
          class="icon-close"
          @click="$emit('close')"
        />
        <ElButton
          v-if="width <= 1100 && secondaryActionText && primaryActionText"
          type="primary"
          @click="$emit(primaryAction)"
          class="accept-button"
          :class="!secondaryActionText ? 'full-width' : ''"
        >
          <Icons
            v-if="primaryActionIcon"
            :name="primaryActionIcon"
            class="mr-2"
          />{{ $t(primaryActionText) }}
        </ElButton>
      </div>
    </template>
    <div class="custom-drawer-scrollable">
      <slot />
    </div>
    <div
      class="custom-drawer-actions"
      v-if="
        secondaryActionText ||
        ((width > 1100 || (width <= 1100 && !secondaryActionText)) &&
          primaryActionText)
      "
    >
      <ElButton
        v-if="secondaryActionText"
        @click="$emit(secondaryAction)"
        type="secondary"
        class="reset-button"
      >
        {{ $t(secondaryActionText) }}
      </ElButton>
      <ElButton
        v-if="
          (width > 1100 || (width <= 1100 && !secondaryActionText)) &&
          primaryActionText
        "
        type="primary"
        @click="$emit(primaryAction)"
        class="accept-button"
        :class="!secondaryActionText ? 'full-width' : ''"
      >
        <Icons
          v-if="primaryActionIcon"
          :name="primaryActionIcon"
          class="mr-2"
        />{{ $t(primaryActionText) }}
      </ElButton>
    </div>
  </el-drawer>
</template>

<script setup>
const { width } = useBreakpoints()
const props = defineProps({
  drawer: {
    type: Boolean,
    default: false,
    required: false
  },
  showClose: {
    type: Boolean,
    default: true,
    required: false
  },
  showBack: {
    type: Boolean,
    default: false,
    required: false
  },
  title: {
    type: String,
    default: '',
    required: false
  },
  primaryAction: {
    type: String,
    default: '',
    required: false
  },
  primaryActionText: {
    type: String,
    default: '',
    required: false
  },
  primaryActionIcon: {
    type: String,
    default: '',
    required: false
  },
  secondaryAction: {
    type: String,
    default: '',
    required: false
  },
  secondaryActionText: {
    type: String,
    default: '',
    required: false
  },
  className: {
    type: String,
    default: '',
    required: false
  }
})
</script>

<style scoped lang="scss">
@media (min-width: 1100px) {
  .icon-back {
    display: inline-block;
    position: relative;
    top: 8px;
    padding: 0;
    margin-right: 5px;
    cursor: pointer;
  }
  .custom-drawer-actions {
    min-width: 100% !important;
    display: table !important;
    margin: 0 auto !important;
    padding: 0 !important;
    height: 36px;
    padding: 16px 0 0 24px !important;
    border-top: 1px solid var(--brand-off-white) !important;
    position: relative;
    box-shadow: 0px -40px 40px 10px rgba(255, 255, 255, 86.88%) !important;
    .reset-button {
      position: absolute;
      left: 30px;
    }
    .accept-button {
      position: absolute;
      top: 17px;
      right: 30px;
      &.full-width {
        right: 0px;
        width: calc(100% - 26px) !important;
      }
    }
  }
}
@media (max-width: 1100px) {
  .icon-back {
    position: relative;
    top: 4px;
    margin-right: 10px;
    display: inline-block;
  }
  .custom-drawer-actions {
  }

  .accept-button {
    &.full-width {
      width: 100% !important;
    }
  }
}
</style>