import { useI18n } from 'vue-i18n';
import { locationTypes } from '~/models/location-types';

export const getLocationTypes = () => {
  const { t } = useI18n();
  return locationTypes.map((type) => {
    return {
      label: t(type.name.toLowerCase()),
      value: type.name
    };
  });
}
