import CREATE_PROVINCE from '~/graphql/provinces/mutation/createProvince.gql'

export async function mutationProvince(variables: any) {
    const { $showError, $sentry } = useNuxtApp()
    const response = await mutation(CREATE_PROVINCE, variables)
    if (response.error) {
        $showError(response.error, t)
        $sentry(response.error, 'mutationProvince')
        return null
    }
    if (response.result) {
        return response.result?.data?.createProvince ?? null
    }
}