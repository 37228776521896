import CREATE_TRANSLATION from '~/graphql/translations/mutation/createTranslation.gql'

export async function mutationTranslation(variables: any, t: any, userLanguage: string) {
    const { $showError, $sentry } = useNuxtApp()
    
    if (!variables.text) {
        $showError(t('fill_in_text_in_language_to_translate', { language: userLanguage.toLowerCase() }))
        return null
    }
    if (!variables.source_lang) {
        $showError(t('your_user_needs_to_setup_language'))
        return null
    }
    const response = await mutation(CREATE_TRANSLATION, variables)
    if (response.error) {
        $showError(response.error, t)
        $sentry(response.error, 'mutationCountry')
        return null
    }
    if (response.result) {
        return response.result?.data?.createTranslation ?? null
    }
}