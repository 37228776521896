import ALL_SIGNING_REASONS from '@/graphql/signing-reasons/query/getAllSigningReasons.gql'

export function apolloSigningReasons(
  params: any,
  filterExists = false,
  filterSaved = null,
  fetchPolicy = null
) {
  return new Promise(async (resolve, reject) => {
    let apolloQuery
    let variables = {}
    try {
      if (filterExists) {
        const orderBy = {
          column: params.sortBy.toUpperCase(),
          order: params.sortDesc ? 'DESC' : 'ASC',
          first: params.first,
          page: params.page
        }
        let searchField = null
        if (params.search) {
          searchField = {
            field: 'where',
            value: {
              OR: [
                {
                  column: 'NAME_EN',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                },
                {
                  column: 'NAME_ES',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                },
                {
                  column: 'NAME_FR',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                },
                {
                  column: 'NAME_DE',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                },
                {
                  column: 'NAME_SV',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                },
                {
                  column: 'NAME_AR',
                  operator: 'LIKE',
                  value: '%' + params.search + '%'
                }
              ]
            }
          }
        }
        apolloQuery = generateQuery('getAllSigningReasons', orderBy, searchField, filterSaved, subSelection())
      } else {
        variables = {
          orderBy: [
            {
              column: params.sortBy.toUpperCase(),
              order: params.sortDesc ? 'DESC' : 'ASC'
            }
          ],
          search: '%' + params.search + '%',
          page: params.page,
          first: params.first
        }
        apolloQuery = ALL_SIGNING_REASONS
      }

      resolve(await query(apolloQuery, variables, { fetchPolicy }))
    } catch (err) {
      reject(err)
    }
  })
}

function subSelection() {
  return (
    '{\n' +
    '    data {\n' +
    '      uuid\n' +
    '      name\n' +
    '      name_en\n' +
    '      name_es\n' +
    '      name_fr\n' +
    '      name_de\n' +
    '      name_sv\n' +
    '      name_ar\n' +
    '      reason\n' +
    '      color\n' +
    '    }\n' +
    '    paginatorInfo {\n' +
    '      currentPage\n' +
    '      lastPage\n' +
    '      total\n' +
    '    }\n' +
    '  }'
  )
}
