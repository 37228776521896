import moment from 'moment'

export function getDiff(endDate: any, startDate: any) {
    if (endDate) {
        return moment(endDate).diff(moment(startDate))
    } else {
        return moment().diff(moment(startDate))
    }
}
export function getDuration(endDate: any, startDate: any) {
    if (endDate) {
        return moment.duration(getDiff(endDate, startDate))
    } else {
        return moment.duration(getDiff(null, startDate))
    }
}

export function getTimeElapsedFormat(timeElapsed: { hours: () => number; days: () => number; minutes: () => string; seconds: () => string; asMilliseconds: () => number }) {
    let result =
        ('0' + (timeElapsed.hours() + timeElapsed.days() * 24)).slice(-2) +
        ':' +
        ('0' + timeElapsed.minutes()).slice(-2) +
        ':' +
        ('0' + timeElapsed.seconds()).slice(-2)
    if (timeElapsed.asMilliseconds() < 0) {
        result = '-' + result
    }
    return result
}

export function getDurations(signings: any[]) {
    let timeElapsed: any = null
    signings.forEach((item: { signout_datetime: any; signing_datetime: any }) => {
        if (timeElapsed) {
            if (item.signout_datetime) {
                timeElapsed = timeElapsed.add(
                    getDuration(item.signout_datetime, item.signing_datetime)
                )
            } else {
                timeElapsed = timeElapsed.add(
                    getDuration(null, item.signing_datetime)
                )
            }
        } else if (item.signout_datetime) {
            timeElapsed = getDuration(
                item.signout_datetime,
                item.signing_datetime
            )
        } else {
            timeElapsed = getDuration(null, item.signing_datetime)
        }
    })
    return timeElapsed
}