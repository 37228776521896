export const countries = [
  {
    name: 'france',
    iso_code: 'FR',
    phone_country: '+33',
    flag: '/flags/france.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Francia',
        sv : 'Frankrike',
        de : 'Frankreich',
        fr : 'France',
        ar : 'فرنسا',
      }
    ]
  },
  {
    name: 'germany',
    iso_code: 'DE',
    phone_country: '+49',
    flag: '/flags/germany.svg',
    max: 11,
    min: 11,
    translation: [
      {
        es : 'Alemania',
        sv : 'Tyskland',
        de : 'Deutschland',
        fr : 'Allemagne',
        ar : 'الألمانية',
      }
    ]
  },
  {
    name: 'italy',
    iso_code: 'IT',
    phone_country: '+39',
    flag: '/flags/italy.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Italia',
        sv : 'Italien',
        de : 'Italien',
        fr : 'Italie',
        ar : 'أيطاليا',
      }
    ]
  },
  {
    name: 'spain',
    iso_code: 'ES',
    phone_country: '+34',
    flag: '/flags/spain.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'España',
        sv : 'Spanien',
        de : 'Spanien',
        fr : 'Espagne',
        ar : 'الإسبانية',
      }
    ]
  },
  {
    name: 'united_states_of_america',
    iso_code: 'US',
    phone_country: '+1',
    flag: '/flags/united states.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Estados Unidos',
        sv : 'USA',
        de : 'USA',
        fr : 'Etats-Unis',
        ar : 'الولايات المتحدة',
      }
    ]
  },
  {
    name: 'united_kingdom',
    iso_code: 'GB',
    phone_country: '+44',
    flag: '/flags/united kingdom.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Reino Unido',
        sv : 'Storbritannien',
        de : 'Großbritannien',
        fr : 'Royaume-Uni',
        ar : 'المملكة المتحدة',
      }
    ]
  },
  {
    name: 'united_arab_emirates',
    iso_code: 'AE',
    phone_country: '+971',
    flag: '/flags/united arab emirates.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Emiratos Arabes Unidos',
        sv : 'Emiraterna',
        de : 'Emiraten',
        fr : 'Emirats Arabes Unis',
        ar : 'الامارات العربية المتحدة',
      }
    ]
  },
  {
    name: 'sweden',
    iso_code: 'SE',
    phone_country: '+46',
    flag: '/flags/sweden.svg',
    max: 9,
    min: 10,
    translation: [
      {
        es : 'Suecia',
        sv : 'Sverige',
        de : 'Schweden',
        fr : 'Suède',
        ar : 'السويد',
      }
    ]
  },
  {
    name: 'saudi_arabia',
    iso_code: 'SA',
    phone_country: '+966',
    flag: '/flags/saudi arabia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Arabia Saudita',
        sv : 'Saudiarabien',
        de : 'Saudi-Arabien',
        fr : 'Arabie saoudite',
        ar : 'السعودية',
      }
    ]
  },
  {
    name: 'afghanistan',
    iso_code: 'AF',
    phone_country: '+93',
    flag: '/flags/afghanistan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Afghanistan',
        sv : 'Afghanistan',
        de : 'Afghanistan',
        fr : 'Afghanistan',
        ar : 'افغانستان',
      }
    ]
  },
  {
    name: 'albania',
    iso_code: 'AL',
    phone_country: '+355',
    flag: '/flags/albania.svg',
    max: 9,
    min: 3,
    translation: [
      {
        es : 'Albania',
        sv : 'Albanien',
        de : 'Albanien',
        fr : 'Albanie',
        ar : 'ألبانيا',
      }
    ]
  },
  {
    name: 'andorra',
    iso_code: 'AD',
    phone_country: '+376',
    flag: '/flags/andorra.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Andorra',
        sv : 'Andorra',
        de : 'Andorra',
        fr : 'Andorre',
        ar : 'أندورا',
      }
    ]
  },
  {
    name: 'angola',
    iso_code: 'AO',
    phone_country: '+244',
    flag: '/flags/angola.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Angola',
        sv : 'Angola',
        de : 'Angola',
        fr : 'Angola',
        ar : 'أنغولا',
      }
    ]
  },
  {
    name: 'antigua_and_barbuda',
    iso_code: 'AG',
    phone_country: '+1-268',
    flag: '/flags/antigua and barbuda.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Antigua y Barbuda',
        sv : 'Antigua och Barbuda',
        de : 'Antigua und Barbuda',
        fr : 'Antigua-et-Barbuda',
        ar : 'أنتيغوا وبربودا',
      }
    ]
  },
  {
    name: 'algeria',
    iso_code: 'DZ',
    phone_country: '+213',
    flag: '/flags/Algeria.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Argelia',
        sv : 'Algeriet',
        de : 'Algerien',
        fr : 'Algérie',
        ar : 'الجزائر',
      }
    ]
  },
  {
    name: 'argentina',
    iso_code: 'AR',
    phone_country: '+54',
    flag: '/flags/argentina.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Argentina',
        sv : 'Argentina',
        de : 'Argentinien',
        fr : 'Argentine',
        ar : 'الأرجنتين',
      }
    ]
  },
  {
    name: 'armenia',
    iso_code: 'AM',
    phone_country: '+374',
    flag: '/flags/armenia.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Armenia',
        sv : 'Armenien',
        de : 'Armenien',
        fr : 'Arménie',
        ar : 'أرمينيا',
      }
    ]
  },
  {
    name: 'aruba',
    iso_code: 'AW',
    phone_country: '+297',
    flag: '/flags/aruba.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Aruba',
        sv : 'Aruba',
        de : 'Aruba',
        fr : 'Aruba',
        ar : 'أروبا',
      }
    ]
  },
  {
    name: 'australia',
    iso_code: 'AU',
    phone_country: '+61',
    flag: '/flags/australia.svg',
    max: 15,
    min: 5,
    translation: [
      {
        es : 'Australia',
        sv : 'Australien',
        de : 'Australien',
        fr : 'Australie',
        ar : 'أستراليا',
      }
    ]
  },
  {
    name: 'austria',
    iso_code: 'AT',
    phone_country: '+43',
    flag: '/flags/austria.svg',
    max: 13,
    min: 4,
    translation: [
      {
        es : 'Austria',
        sv : 'Österrike',
        de : 'Österreich',
        fr : 'Autriche',
        ar : 'النمسا',
      }
    ]
  },
  {
    name: 'azerbaijan',
    iso_code: 'AZ',
    phone_country: '+994',
    flag: '/flags/azerbaijan.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es : 'Azerbaiyán',
        sv : 'Azerbajdzjan',
        de : 'Aserbaidschan',
        fr : 'Azerbaïdjan',
        ar : 'أذربيجان',
      }
    ]
  },
  {
    name: 'belgium',
    iso_code: 'BE',
    phone_country: '+32',
    flag: '/flags/belgium.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Bélgica',
        sv : 'Belgien',
        de : 'Belgien',
        fr : 'Belgique',
        ar : 'بلجيكا',
      }
    ]
  },
  {
    name: 'bahamas',
    iso_code: 'BS',
    phone_country: '+1-242',
    flag: '/flags/bahamas.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Bahamas',
        sv : 'Bahamas',
        de : 'Bahamas',
        fr : 'Bahamas',
        ar : 'الباهاما',
      }
    ]
  },
  {
    name: 'bahrain',
    iso_code: 'BH',
    phone_country: '+973',
    flag: '/flags/bahrain.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Bahréin',
        sv : 'Bahrain',
        de : 'Bahrain',
        fr : 'Bahreïn',
        ar : 'البحرين',
      }
    ]
  },
  {
    name: 'bangladesh',
    iso_code: 'BD',
    phone_country: '+880',
    flag: '/flags/bangladesh.svg',
    max: 10,
    min: 6,
    translation: [
      {
        es : 'Bangladesh',
        sv : 'Bangladesh',
        de : 'Bangladesch',
        fr : 'Bangladesh',
        ar : 'بنغلاديش',
      }
    ]
  },
  {
    name: 'barbados',
    iso_code: 'BB',
    phone_country: '+1-246',
    flag: '/flags/barbados.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Barbados',
        sv : 'Barbados',
        de : 'Barbados',
        fr : 'Barbade',
        ar : 'باربادوس',
      }
    ]
  },
  {
    name: 'belize',
    iso_code: 'BZ',
    phone_country: '+501',
    flag: '/flags/belize.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Belice',
        sv : 'Belize',
        de : 'Belize',
        fr : 'Belize',
        ar : 'بيليز',
      }
    ]
  },
  {
    name: 'benin',
    iso_code: 'BJ',
    phone_country: '+229',
    flag: '/flags/benin.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Benín',
        sv : 'Benin',
        de : 'Benin',
        fr : 'Bénin',
        ar : 'بنين',
      }
    ]
  },
  {
    name: 'bhutan',
    iso_code: 'BT',
    phone_country: '+975',
    flag: '/flags/bhutan.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es : 'Bután',
        sv : 'Bhutan',
        de : 'Bhutan',
        fr : 'Bhoutan',
        ar : 'بوتان',
      }
    ]
  },
  {
    name: 'belarus',
    iso_code: 'BY',
    phone_country: '+375',
    flag: '/flags/belarus.svg',
    max: 10,
    min: 9,
    translation: [
      {
        es : 'Bielorrusia',
        sv : 'Bielorrusia',
        de : 'Bielorussia',
        fr : 'Biélorussie',
        ar : 'بيلاروسيا',
      }
    ]
  },
  {
    name: 'bolivia',
    iso_code: 'BO',
    phone_country: '+591',
    flag: '/flags/bolivia.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Bolivia',
        sv : 'Bolivia',
        de : 'Bolivia',
        fr : 'Bolivie',
        ar : 'بوليفيا',
      }
    ]
  },
  {
    name: 'bosnia_and_herzegovina',
    iso_code: 'BA',
    phone_country: '+387',
    flag: '/flags/bosnia and herzegovina.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Bosnia y Herzegovina',
        sv : 'Bosnien och Hercegovina',
        de : 'Bosnien und Herzegowina',
        fr : 'Bosnie-Herzégovine',
        ar : 'البوسنة والهرسكوغونا',
      }
    ]
  },
  {
    name: 'botswana',
    iso_code: 'BW',
    phone_country: '+267',
    flag: '/flags/botswana.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es : 'Botswana',
        sv : 'Botswana',
        de : 'Botswana',
        fr : 'Botswana',
        ar : 'بوتسوانا',
      }
    ]
  },
  {
    name: 'brazil',
    iso_code: 'BR',
    phone_country: '+55',
    flag: '/flags/brazil.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Brasil',
        sv : 'Brasilien',
        de : 'Brasilien',
        fr : 'Brésil',
        ar : 'البرازيل',
      }
    ]
  },
  {
    name: 'brunei',
    iso_code: 'BN',
    phone_country: '+673',
    flag: '/flags/brunei.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Brunei',
        sv : 'Brunei',
        de : 'Brunei',
        fr : 'Brunei',
        ar : 'بروني',
      }
    ]
  },
  {
    name: 'bulgaria',
    iso_code: 'BG',
    phone_country: '+359',
    flag: '/flags/bulgaria.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Bulgaria',
        sv : 'Bulgarien',
        de : 'Bulgarien',
        fr : 'Bulgarie',
        ar : 'بلغاريا',
      }
    ]
  },
  {
    name: 'burkina_faso',
    iso_code: 'BF',
    phone_country: '+226',
    flag: '/flags/burkina faso.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Burkina Faso',
        sv : 'Burkina Faso',
        de : 'Burkina Faso',
        fr : 'Burkina Faso',
        ar : 'بوركينا فاسو',
      }
    ]
  },
  {
    name: 'burundi',
    iso_code: 'BI',
    phone_country: '+257',
    flag: '/flags/burundi.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Burundi',
        sv : 'Burundi',
        de : 'Burundi',
        fr : 'Burundi',
        ar : 'بوروندي',
      }
    ]
  },
  {
    name: 'cape_verde',
    iso_code: 'CV',
    phone_country: '+238',
    flag: '/flags/cape verde.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Cabo Verde',
        sv : 'Cabo Verde',
        de : 'Cabo Verde',
        fr : 'Cabo Verde',
        ar : 'الرأس الأخضر',
      }
    ]
  },
  {
    name: 'cambodia',
    iso_code: 'KH',
    phone_country: '+855',
    flag: '/flags/cambodia.svg',
    translation: [
      {
        es : 'Camboya',
        sv : 'Kambodja',
        de : 'Kambodscha',
        fr : 'Cambodge',
        ar : 'كمبوديا',
      }
    ]
  },
  {
    name: 'cameroon',
    iso_code: 'CM',
    phone_country: '+237',
    flag: '/flags/cameroon.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Camerún',
        sv : 'Kamerun',
        de : 'Kamerun',
        fr : 'Cameroun',
        ar : 'الكاميرون',
      }
    ]
  },
  {
    name: 'canada',
    iso_code: 'CA',
    phone_country: '+1',
    flag: '/flags/canada.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Canadá',
        sv : 'Kanada',
        de : 'Kanada',
        fr : 'Canada',
        ar : 'كندا',
      }
    ]
  },
  {
    name: 'chad',
    iso_code: 'TD',
    phone_country: '+235',
    flag: '/flags/chad.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Chad',
        sv : 'Tchad',
        de : 'Tschad',
        fr : 'Tchad',
        ar : 'تشاد',
      }
    ]
  },
  {
    name: 'chile',
    iso_code: 'CL',
    phone_country: '+56',
    flag: '/flags/chile.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es : 'Chile',
        sv : 'Chile',
        de : 'Chile',
        fr : 'Chili',
        ar : 'تشيلي',
      }
    ]
  },
  {
    name: 'china',
    iso_code: 'CN',
    phone_country: '+86',
    flag: '/flags/china.svg',
    max: 12,
    min: 5,
    translation: [
      {
        es : 'China',
        sv : 'Kina',
        de : 'China',
        fr : 'Chine',
        ar : 'الصين',
      }
    ]
  },
  {
    name: 'cyprus',
    iso_code: 'CY',
    phone_country: '+357',
    flag: '/flags/cyprus.svg',
    max: 11,
    min: 8,
    translation: [
      {
        es : 'Chipre',
        sv : 'Cypern',
        de : 'Zypern',
        fr : 'Chypre',
        ar : 'قبرص',
      }
    ]
  },
  {
    name: 'vatican_city',
    iso_code: 'VA',
    phone_country: '+379',
    flag: '/flags/vatican city.svg',
    max: 11,
    min: 11,
    translation: [
      {
        es : 'Ciudad del Vaticano',
        sv : 'Vatikanstaten',
        de : 'Vatikanstadt',
        fr : 'Vatican',
        ar : 'الفاتيكان',
      }
    ]
  },
  {
    name: 'colombia',
    iso_code: 'CO',
    phone_country: '+57',
    flag: '/flags/colombia.svg',
    max: 10,
    min: 8,
    translation: [
      {
        es : 'Colombia',
        sv : 'Colombia',
        de : 'Kolumbien',
        fr : 'Colombie',
        ar : 'كولومبيا',
      }
    ]
  },
  {
    name: 'comoros',
    iso_code: 'KM',
    phone_country: '+269',
    flag: '/flags/comoros.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Comoras',
        sv : 'Komorerna',
        de : 'Komoren',
        fr : 'Comores',
        ar : 'جزر القمر',
      }
    ]
  },
  {
    name: 'republic_of_the_congo',
    iso_code: 'CG',
    phone_country: '+242',
    flag: '/flags/republic of the congo.svg',
    max: 9,
    min: 5,
    translation: [
      {
        es : 'República del Congo',
        sv : 'Kongo-Brazzaville',
        de : 'Kongo',
        fr : 'République du Congo',
        ar : 'جمهورية الكونغو',
      }
    ]
  },
  {
    name: 'north_korea',
    iso_code: 'KP',
    phone_country: '+850',
    flag: '/flags/north korea.svg',
    max: 17,
    min: 6,
    translation: [
      {
        es : 'Corea del Norte',
        sv : 'Nordkorea',
        de : 'Nordkorea',
        fr : 'Corée du Nord',
        ar : 'كوريا الشمالية',
      }
    ]
  },
  {
    name: 'south_korea',
    iso_code: 'KR',
    phone_country: '+82',
    flag: '/flags/south korea.svg',
    max: 11,
    min: 8,
    translation: [
      {
        es : 'Corea del Sur',
        sv : 'Sydkorea',
        de : 'Südkorea',
        fr : 'Corée du Sud',
        ar : 'كوريا الجنوبية',
      }
    ]
  },
  {
    name: 'ivory_coast',
    iso_code: 'CI',
    phone_country: '+225',
    flag: '/flags/ivory coast.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Costa de Marfil',
        sv : 'Elfenbenskusten',
        de : 'Elfenbeinkusten',
        fr : 'Côte d’Ivoire',
        ar : 'جمهورية الكويت',
      }
    ]
  },
  {
    name: 'costa_rica',
    iso_code: 'CR',
    phone_country: '+506',
    flag: '/flags/costa rica.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Costa Rica',
        sv : 'Costa Rica',
        de : 'Costa Rica',
        fr : 'Costa Rica',
        ar : 'كوستاريكا',
      }
    ]
  },
  {
    name: 'croatia',
    iso_code: 'HR',
    phone_country: '+385',
    flag: '/flags/croatia.svg',
    max: 12,
    min: 8,
    translation: [
      {
        es : 'Croacia',
        sv : 'Kroatien',
        de : 'Kroatien',
        fr : 'Croatie',
        ar : 'الكرواتية',
      }
    ]
  },
  {
    name: 'cuba',
    iso_code: 'CU',
    phone_country: '+53',
    flag: '/flags/cuba.svg',
    max: 8,
    min: 6,
    translation: [
      {
        es : 'Cuba',
        sv : 'Kuba',
        de : 'Kuba',
        fr : 'Cuba',
        ar : 'كوبا',
      }
    ]
  },
  {
    name: 'curaçao',
    iso_code: 'CW',
    phone_country: '+599',
    flag: '/flags/curacao.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es : 'Curazao',
        sv : 'Curaçao',
        de : 'Curaçao',
        fr : 'Curaçao',
        ar : 'كوراساو',
      }
    ]
  },
  {
    name: 'denmark',
    iso_code: 'DK',
    phone_country: '+45',
    flag: '/flags/denmark.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Dinamarca',
        sv : 'Danmark',
        de : 'Dänemark',
        fr : 'Danemark',
        ar : 'الدنمارك',
      }
    ]
  },
  {
    name: 'dominica',
    iso_code: 'DM',
    phone_country: '+1-767',
    flag: '/flags/dominica.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Dominica',
        sv : 'Dominica',
        de : 'Dominica',
        fr : 'Dominique',
        ar : 'دومينيكا',
      }
    ]
  },
  {
    name: 'ecuador',
    iso_code: 'EC',
    phone_country: '+593',
    flag: '/flags/ecuador.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Ecuador',
        sv : 'Ecuador',
        de : 'Ecuador',
        fr : 'Ecuador',
        ar : 'اكوادور',
      }
    ]
  },
  {
    name: 'egypt',
    iso_code: 'EG',
    phone_country: '+20',
    flag: '/flags/egypt.svg',
    max: 9,
    min: 7,
    translation: [
      {
        es : 'Egipto',
        sv : 'Egypten',
        de : 'Ägypten',
        fr : 'Egypte',
        ar : 'مصر',
      }
    ]
  },
  {
    name: 'el_salvador',
    iso_code: 'SV',
    phone_country: '+503',
    flag: '/flags/el salvador.svg',
    max: 11,
    min: 7,
    translation: [
      {
        es : 'El Salvador',
        sv : 'El Salvador',
        de : 'El Salvador',
        fr : 'El Salvador',
        ar : 'السلفادور',
      }
    ]
  },
  {
    name: 'eritrea',
    iso_code: 'ER',
    phone_country: '+291',
    flag: '/flags/eritrea.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Eritrea',
        sv : 'Eritrea',
        de : 'Eritrea',
        fr : 'Érythrée',
        ar : 'إريتيريا',
      }
    ]
  },
  {
    name: 'slovakia',
    iso_code: 'SK',
    phone_country: '+421',
    flag: '/flags/slovakia.svg',
    max: 9,
    min: 4,
    translation: [
      {
        es : 'Eslovenia',
        sv : 'Slovakien',
        de : 'Slowakei',
        fr : 'Slovaquie',
        ar : 'سلوفاكيا',
      }
    ]
  },
  {
    name: 'slovenia',
    iso_code: 'YES',
    phone_country: '+386',
    flag: '/flags/slovenia.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Eslovenia',
        sv : 'Slovakien',
        de : 'Slowakei',
        fr : 'Slovaquie',
        ar : 'سلوفاكيا',
      }
    ]
  },
  {
    name: 'estonia',
    iso_code: 'EE',
    phone_country: '+372',
    flag: '/flags/estonia.svg',
    max: 10,
    min: 7,
    translation: [
      {
        es : 'Estonia',
        sv : 'Estland',
        de : 'Estland',
        fr : 'Estonie',
        ar : 'استونيا',
      }
    ]
  },
  {
    name: 'ethiopia',
    iso_code: 'ET',
    phone_country: '+251',
    flag: '/flags/ethiopia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Etiopia',
        sv : 'Etiopia',
        de : 'Äthiopien',
        fr : 'Éthiopie',
        ar : 'إثيوبيا',
      }
    ]
  },
  {
    name: 'philippines',
    iso_code: 'PH',
    phone_country: '+63',
    flag: '/flags/philippines.svg',
    max: 10,
    min: 8,
    translation: [
      {
        es : 'Filipinas',
        sv : 'Filippinerna',
        de : 'Philippinen',
        fr : 'Philippines',
        ar : 'الفلبين',
      }
    ]
  },
  {
    name: 'finland',
    iso_code: 'FI',
    phone_country: '+358',
    flag: '/flags/finland.svg',
    max: 12,
    min: 5,
    translation: [
      {
        es : 'Finlandia',
        sv : 'Finland',
        de : 'Finnland',
        fr : 'Finlande',
        ar : 'فنلندا',
      }
    ]
  },
  {
    name: 'fiji',
    iso_code: 'FJ',
    phone_country: '+679',
    flag: '/flags/fiji.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es : 'Fiyi',
        sv : 'Fiji',
        de : 'Fiji',
        fr : 'Fidji',
        ar : 'فيجي',
      }
    ]
  },
  {
    name: 'gabon',
    iso_code: 'GA',
    phone_country: '+241',
    flag: '/flags/gabon.svg',
    max: 7,
    min: 6,
    translation: [
      {
        es : 'Gabon',
        sv : 'Gabon',
        de : 'Gabun',
        fr : 'Gabon',
        ar : 'الجابون',
      }
    ]
  },
  {
    name: 'gambia',
    iso_code: 'GM',
    phone_country: '+220',
    flag: '/flags/gambia.svg',
    max: 7,
    min: 7
  },
  {
    name: 'georgia',
    iso_code: 'GE',
    phone_country: '+995',
    flag: '/flags/georgia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es : 'Georgia',
        sv : 'Georgien',
        de : 'Georgien',
        fr : 'Géorgie',
        ar : 'جورجيا',
      }
    ]
  },
  {
    name: 'ghana',
    iso_code: 'GH',
    phone_country: '+233',
    flag: '/flags/ghana.svg',
    max: 9,
    min: 5,
    translation: [
      {
        es : 'Ghana',
        sv : 'Ghana',
        de : 'Ghana',
        fr : 'Ghana',
        ar : 'غانا',
      }
    ]
  },
  {
    name: 'gibraltar',
    iso_code: 'GI',
    phone_country: '+350',
    flag: '/flags/gibraltar.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Gibraltar',
        sv : 'Gibraltar',
        de : 'Gibraltar',
        fr : 'Gibraltar',
        ar : 'جبل طارق',
      }
    ]
  },
  {
    name: 'greece',
    iso_code: 'GR',
    phone_country: '+30',
    flag: '/flags/greece.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Grecia',
        sv : 'Grekland',
        de : 'Griechenland',
        fr : 'Grèce',
        ar : 'اليونان',
      }
    ]
  },
  {
    name: 'greenland',
    iso_code: 'GL',
    phone_country: '+299',
    flag: '/flags/greenland.svg',
    max: 6,
    min: 6,
    translation: [
      {
        es : 'Groenlandia',
        sv : 'Groenland',
        de : 'Groenland',
        fr : 'Groenland',
        ar : 'جرينلاند',
      }
    ]
  },
  {
    name: 'guam',
    iso_code: 'GU',
    phone_country: '+1-671',
    flag: '/flags/guam.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es : 'Guam',
        sv : 'Guam',
        de : 'Guam',
        fr : 'Guam',
        ar : 'غوام',
      }
    ]
  },
  {
    name: 'guatemala',
    iso_code: 'GT',
    phone_country: '+502',
    flag: '/flags/guatemala.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Guatemala',
        sv : 'Guatemala',
        de : 'Guatemala',
        fr : 'Guatemala',
        ar : 'غواتيمالا',
      }
    ]
  },
  {
    name: 'guernsey',
    iso_code: 'GG',
    phone_country: '+44-1481',
    flag: '/flags/guernsey.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es : 'Guernsey',
        sv : 'Guernsey',
        de : 'Guernsey',
        fr : 'Guernesey',
        ar : 'غيرنزي',
      }
    ]
  },
  {
    name: 'guinea',
    iso_code: 'GN',
    phone_country: '+224',
    flag: '/flags/guinea.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Guinea',
        sv: 'Guinea',
        de: 'Guinea',
        fr: 'Guinée',
        ar: 'غينيا',
      }
    ]
  },
  {
    name: 'equatorial_guinea',
    iso_code: 'GQ',
    phone_country: '+240',
    flag: '/flags/equatorial guinea.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Guinea Ecuatorial',
        sv: 'Ekvatorialguinea',
        de: 'Äquatorialguinea',
        fr: 'Guinée équatoriale',
        ar: 'غينيا الاستوائية',
      }
    ]
  },
  {
    name: 'guinea_bissau',
    iso_code: 'GW',
    phone_country: '+245',
    flag: '/flags/guinea bissau.svg',
    translation: [
      {
        es: 'Guinea-Bisáu',
        sv: 'Guinea-Bissau',
        de: 'Guinea-Bissau',
        fr: 'Guinée-Bissau',
        ar: 'غينيا بيساو',
      }
    ]
  },
  {
    name: 'guyana',
    iso_code: 'GY',
    phone_country: '+592',
    flag: '/flags/guyana.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Guyana',
        sv: 'Guyana',
        de: 'Guyana',
        fr: 'Guyane',
        ar: 'غيانا',
      }
    ]
  },
  {
    name: 'haiti',
    iso_code: 'HT',
    phone_country: '+509',
    flag: '/flags/haiti.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Haití',
        sv: 'Haiti',
        de: 'Haiti',
        fr: 'Haïti',
        ar: 'هايتي',
      }
    ]
  },
  {
    name: 'honduras',
    iso_code: 'HN',
    phone_country: '+504',
    flag: '/flags/honduras.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Honduras',
        sv: 'Honduras',
        de: 'Honduras',
        fr: 'Honduras',
        ar: 'هندوراس',
      }
    ]
  },
  {
    name: 'hong_kong',
    iso_code: 'HK',
    phone_country: '+852',
    flag: '/flags/hong kong.svg',
    max: 9,
    min: 4,
    translation: [
      {
        es: 'Hong Kong',
        sv: 'Hongkong',
        de: 'Hongkong',
        fr: 'Hong Kong',
        ar: 'هونغ كونغ',
      }
    ]
  },
  {
    name: 'hungary',
    iso_code: 'HU',
    phone_country: '+36',
    flag: '/flags/hungary.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Hungría',
        sv: 'Ungern',
        de: 'Ungarn',
        fr: 'Hongrie',
        ar: 'هنغاريا',
      }
    ]
  },
  {
    name: 'india',
    iso_code: 'IN',
    phone_country: '+91',
    flag: '/flags/india.svg',
    max: 10,
    min: 7,
    translation: [
      {
        es: 'India',
        sv: 'Indien',
        de: 'Indien',
        fr: 'Inde',
        ar: 'الهند',
      }
    ]
  },
  {
    name: 'indonesia',
    iso_code: 'ID',
    phone_country: '+62',
    flag: '/flags/indonesia.svg',
    max: 10,
    min: 5,
    translation: [
      {
        es: 'Indonesia',
        sv: 'Indonesien',
        de: 'Indonesien',
        fr: 'Indonésie',
        ar: 'إندونيسيا',
      }
    ]
  },
  {
    name: 'iran',
    iso_code: 'IR',
    phone_country: '+98',
    flag: '/flags/iran.svg',
    max: 10,
    min: 6,
    translation: [
      {
        es: 'Irán',
        sv: 'Iran',
        de: 'Iran',
        fr: 'Iran',
        ar: 'إيران',
      }
    ]
  },  
  {
    name: 'iraq',
    iso_code: 'IQ',
    phone_country: '+964',
    flag: '/flags/iraq.svg',
    max: 10,
    min: 8,
    translation: [
      {
        es: 'Irak',
        sv: 'Irak',
        de: 'Irak',
        fr: 'Irak',
        ar: 'العراق',
      }
    ]
  },
  {
    name: 'ireland',
    iso_code: 'IE',
    phone_country: '+353',
    flag: '/flags/ireland.svg',
    max: 11,
    min: 7,
    translation: [
      {
        es: 'Irlanda',
        sv: 'Irland',
        de: 'Irland',
        fr: 'Irlande',
        ar: 'أيرلندا',
      }
    ]
  },
  {
    name: 'isle_of_man',
    iso_code: 'IM',
    phone_country: '+44-1624',
    flag: '/flags/isle of man.svg',
    translation: [
      {
        es: 'Isla de Man',
        sv: 'Isle of Man',
        de: 'Isle of Man',
        fr: 'Île de Man',
        ar: 'جزيرة مان',
      }
    ]
  },
  {
    name: 'norfolk_island',
    iso_code: 'NF',
    phone_country: '+672',
    flag: '/flags/norfolk island.svg',
    translation: [
      {
        es: 'Isla Norfolk',
        sv: 'Norfolk Island',
        de: 'Norfolk Island',
        fr: 'Île Norfolk',
        ar: 'جزيرة نورفولك',
      }
    ]
  },
  {
    name: 'iceland',
    iso_code: 'IS',
    phone_country: '+354',
    flag: '/flags/iceland.svg',
    max: 9,
    min: 7,
    translation: [
      {
        es: 'Islandia',
        sv: 'Island',
        de: 'Island',
        fr: 'Islande',
        ar: 'آيسلندا',
      }
    ]
  },
  {
    name: 'bermuda_islands',
    iso_code: 'BM',
    phone_country: '+1-441',
    flag: '/flags/bermuda.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Islas Bermudas',
        sv: 'Bermudaöarna',
        de: 'Bermuda-Inseln',
        fr: 'Îles Bermudes',
        ar: 'جزر برمودا',
      }
    ]
  },
  {
    name: 'cayman_islands',
    iso_code: 'KY',
    phone_country: '+1-345',
    flag: '/flags/cayman islands.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Islas Caimán',
        sv: 'Caymanöarna',
        de: 'Kaimaninseln',
        fr: 'Îles Caïmans',
        ar: 'جزر كايمان',
      }
    ]
  },
  {
    name: 'cocos_keeling_islands',
    iso_code: 'CC',
    phone_country: '+61',
    flag: '/flags/cocos island.svg',
    translation: [
      {
        es: 'Islas Cocos (Keeling)',
        sv: 'Cocosöarna',
        de: 'Kokosinseln',
        fr: 'Îles Cocos (Keeling)',
        ar: 'جزر كوكوس (كيلينغ)',
      }
    ]
  },
  {
    name: 'cook_islands',
    iso_code: 'CK',
    phone_country: '+682',
    flag: '/flags/cook islands.svg',
    max: 5,
    min: 5,
    translation: [
      {
        es: 'Islas Cook',
        sv: 'Cooköarna',
        de: 'Cookinseln',
        fr: 'Îles Cook',
        ar: 'جزر كوك',
      }
    ]
  },
  {
    name: 'faroe_islands',
    iso_code: 'FO',
    phone_country: '+298',
    flag: '/flags/faroe islands.svg',
    max: 6,
    min: 6,
    translation: [
      {
        es: 'Islas Feroe',
        sv: 'Färöarna',
        de: 'Färöer Inseln',
        fr: 'Îles Féroé',
        ar: 'جزر فارو',
      }
    ]
  },
  {
    name: 'maldives_islands',
    iso_code: 'MV',
    phone_country: '+960',
    flag: '/flags/maldives.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Maldivas',
        sv: 'Maldiverna',
        de: 'Malediven',
        fr: 'Maldives',
        ar: 'جزر المالديف',
      }
    ]
  },
  {
    name: 'falkland_islands',
    iso_code: 'FK',
    phone_country: '+500',
    flag: '/flags/falkland islands.svg',
    max: 5,
    min: 5,
    translation: [
      {
        es: 'Islas Malvinas',
        sv: 'Falklandsöarna',
        de: 'Falklandinseln',
        fr: 'Îles Malouines',
        ar: 'جزر فوكلاند',
      }
    ]
  },
  {
    name: 'northern_mariana_islands',
    iso_code: 'MP',
    phone_country: '+1-670',
    flag: '/flags/northern marianas islands.svg',
    translation: [
      {
        es: 'Islas Marianas del Norte',
        sv: 'Nordmarianerna',
        de: 'Nördliche Marianen',
        fr: 'Îles Mariannes du Nord',
        ar: 'جزر ماريانا الشمالية',
      }
    ]
  },
  {
    name: 'marshall_islands',
    iso_code: 'MH',
    phone_country: '+692',
    flag: '/flags/marshall island.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Islas Marshall',
        sv: 'Marshallöarna',
        de: 'Marshallinseln',
        fr: 'Îles Marshall',
        ar: 'جزر مارشال',
      }
    ]
  },
  {
    name: 'pitcairn_islands',
    iso_code: 'PN',
    phone_country: '+870',
    flag: '/flags/pitcairn islands.svg',
    translation: [
      {
        es: 'Islas Pitcairn',
        sv: 'Pitcairnöarna',
        de: 'Pitcairninseln',
        fr: 'Îles Pitcairn',
        ar: 'جزر بيتكيرن',
      }
    ]
  },
  {
    name: 'solomon_islands',
    iso_code: 'SB',
    phone_country: '+677',
    flag: '/flags/solomon islands.svg',
    max: 5,
    min: 5,
    translation: [
      {
        es: 'Islas Salomón',
        sv: 'Salomonöarna',
        de: 'Salomonen',
        fr: 'Îles Salomon',
        ar: 'جزر سليمان',
      }
    ]
  },
  {
    name: 'turks_and_caicos_islands',
    iso_code: 'TC',
    phone_country: '+1-649',
    flag: '/flags/turks and caicos.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Islas Turcas y Caicos',
        sv: 'Turks- och Caicosöarna',
        de: 'Turks- und Caicosinseln',
        fr: 'Îles Turques-et-Caïques',
        ar: 'جزر تركس وكايكوس',
      }
    ]
  },
  {
    name: 'british_virgin_islands',
    iso_code: 'VG',
    phone_country: '+1-284',
    flag: '/flags/british virgin islands.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Islas Vírgenes Británicas',
        sv: 'Brittiska Jungfruöarna',
        de: 'Britische Jungferninseln',
        fr: 'Îles Vierges britanniques',
        ar: 'جزر فيرجن البريطانية',
      }
    ]
  },
  {
    name: 'united_states_virgin_islands',
    iso_code: 'VI',
    phone_country: '+1-340',
    flag: '/flags/virgin islands.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Islas Vírgenes de los Estados Unidos',
        sv: 'Amerikanska Jungfruöarna',
        de: 'Amerikanische Jungferninseln',
        fr: 'Îles Vierges des États-Unis',
        ar: 'جزر فيرجن الأمريكية',
      }
    ]
  },
  {
    name: 'israel',
    iso_code: 'IL',
    phone_country: '+972',
    flag: '/flags/israel.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Israel',
        sv: 'Israel',
        de: 'Israel',
        fr: 'Israël',
        ar: 'إسرائيل',
      }
    ]
  },
  {
    name: 'jamaica',
    iso_code: 'JM',
    phone_country: '+1-876',
    flag: '/flags/jamaica.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Jamaica',
        sv: 'Jamaica',
        de: 'Jamaika',
        fr: 'Jamaïque',
        ar: 'جامايكا',
      }
    ]
  },
  {
    name: 'japan',
    iso_code: 'JP',
    phone_country: '+81',
    flag: '/flags/japan.svg',
    max: 12,
    min: 9,
    translation: [
      {
        es: 'Japón',
        sv: 'Japan',
        de: 'Japan',
        fr: 'Japon',
        ar: 'اليابان',
      }
    ]
  },
  {
    name: 'jersey',
    iso_code: 'JE',
    phone_country: '+44-1534',
    flag: '/flags/jersey.svg',
    translation: [
      {
        es: 'Jersey',
        sv: 'Jersey',
        de: 'Jersey',
        fr: 'Jersey',
        ar: 'جيرسي',
      }
    ]
  },
  {
    name: 'jordan',
    iso_code: 'JO',
    phone_country: '+962',
    flag: '/flags/jordan.svg',
    max: 9,
    min: 5,
    translation: [
      {
        es: 'Jordania',
        sv: 'Jordanien',
        de: 'Jordanien',
        fr: 'Jordanie',
        ar: 'الأردن',
      }
    ]
  },
  {
    name: 'kazakhstan',
    iso_code: 'KZ',
    phone_country: '+7',
    flag: '/flags/kazakhstan.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Kazajistán',
        sv: 'Kazakhstan',
        de: 'Kasachstan',
        fr: 'Kazakhstan',
        ar: 'كازاخستان',
      }
    ]
  },
  {
    name: 'kenya',
    iso_code: 'KE',
    phone_country: '+254',
    flag: '/flags/kenya.svg',
    max: 10,
    min: 6,
    translation: [
      {
        es: 'Kenia',
        sv: 'Kenya',
        de: 'Kenia',
        fr: 'Kenya',
        ar: 'كينيا',
      }
    ]
  },
  {
    name: 'kyrgyzstan',
    iso_code: 'KG',
    phone_country: '+996',
    flag: '/flags/kyrgyzstan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Kirguistán',
        sv: 'Kirgizistan',
        de: 'Kirgisistan',
        fr: 'Kirghizistan',
        ar: 'قيرغيزستان',
      }
    ]
  },
  {
    name: 'kiribati',
    iso_code: 'KI',
    phone_country: '+686',
    flag: '/flags/kiribati.svg',
    max: 5,
    min: 5,
    translation: [
      {
        es: 'Kiribati',
        sv: 'Kiribati',
        de: 'Kiribati',
        fr: 'Kiribati',
        ar: 'كيريباتي',
      }
    ]
  },
  {
    name: 'kuwait',
    iso_code: 'KW',
    phone_country: '+965',
    flag: '/flags/kuwait.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Kuwait',
        sv: 'Kuwait',
        de: 'Kuwait',
        fr: 'Koweït',
        ar: 'الكويت',
      }
    ]
  },
  {
    name: 'lebanon',
    iso_code: 'LB',
    phone_country: '+961',
    flag: '/flags/lebanon.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Líbano',
        sv: 'Libanon',
        de: 'Libanon',
        fr: 'Liban',
        ar: 'لبنان',
      }
    ]
  },
  {
    name: 'laos',
    iso_code: 'LA',
    phone_country: '+856',
    flag: '/flags/laos.svg',
    max: 10,
    min: 8,
    translation: [
      {
        es: 'Laos',
        sv: 'Laos',
        de: 'Laos',
        fr: 'Laos',
        ar: 'لاوس',
      }
    ]
  },
  {
    name: 'lesotho',
    iso_code: 'LS',
    phone_country: '+266',
    flag: '/flags/lesotho.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Lesoto',
        sv: 'Lesotho',
        de: 'Lesotho',
        fr: 'Lesotho',
        ar: 'ليسوتو',
      }
    ]
  },
  {
    name: 'latvia',
    iso_code: 'LV',
    phone_country: '+371',
    flag: '/flags/latvia.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Letonia',
        sv: 'Lettland',
        de: 'Lettland',
        fr: 'Lettonie',
        ar: 'لاتفيا',
      }
    ]
  },
  {
    name: 'liberia',
    iso_code: 'LR',
    phone_country: '+231',
    flag: '/flags/liberia.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Liberia',
        sv: 'Liberia',
        de: 'Liberia',
        fr: 'Libéria',
        ar: 'ليبيريا',
      }
    ]
  },
  {
    name: 'libya',
    iso_code: 'LY',
    phone_country: '+218',
    flag: '/flags/libya.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Libia',
        sv: 'Libyen',
        de: 'Libyen',
        fr: 'Libye',
        ar: 'ليبيا',
      }
    ]
  },
  {
    name: 'liechtenstein',
    iso_code: 'LI',
    phone_country: '+423',
    flag: '/flags/liechtenstein.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Liechtenstein',
        sv: 'Liechtenstein',
        de: 'Liechtenstein',
        fr: 'Liechtenstein',
        ar: 'ليختنشتاين',
      }
    ]
  },
  {
    name: 'lithuania',
    iso_code: 'LT',
    phone_country: '+370',
    flag: '/flags/lithuania.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Lituania',
        sv: 'Litauen',
        de: 'Litauen',
        fr: 'Lituanie',
        ar: 'ليتوانيا',
      }
    ]
  },
  {
    name: 'luxembourg',
    iso_code: 'LU',
    phone_country: '+352',
    flag: '/flags/luxembourg.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Luxemburgo',
        sv: 'Luxemburg',
        de: 'Luxemburg',
        fr: 'Luxembourg',
        ar: 'لوكسمبورغ',
      }
    ]
  },
  {
    name: 'mexico',
    iso_code: 'MX',
    phone_country: '+52',
    flag: '/flags/mexico.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'México',
        sv: 'Mexiko',
        de: 'Mexiko',
        fr: 'Mexique',
        ar: 'المكسيك',
      }
    ]
  },
  {
    name: 'monaco',
    iso_code: 'MC',
    phone_country: '+377',
    flag: '/flags/monaco.svg',
    max: 9,
    min: 5,
    translation: [
      {
        es: 'Mónaco',
        sv: 'Monaco',
        de: 'Monaco',
        fr: 'Monaco',
        ar: 'موناكو',
      }
    ]
  },
  {
    name: 'macao',
    iso_code: 'MO',
    phone_country: '+853',
    flag: '/flags/macao.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Macau',
        sv: 'Macau',
        de: 'Macau',
        fr: 'Macau',
        ar: 'ماكاو',
      }
    ]
  },
  {
    name: 'macedônia',
    iso_code: 'MK',
    phone_country: '+389',
    flag: '/flags/republic of macedonia.svg',
    translation: [
      {
        es: 'Macedonia',
        sv: 'Makedonien',
        de: 'Mazedonien',
        fr: 'Macédoine',
        ar: 'مقدونيا',
      }
    ]
  },
  {
    name: 'madagascar',
    iso_code: 'MG',
    phone_country: '+261',
    flag: '/flags/madagascar.svg',
    max: 10,
    min: 9,
    translation: [
      {
        es: 'Madagascar',
        sv: 'Madagaskar',
        de: 'Madagaskar',
        fr: 'Madagascar',
        ar: 'مدغشقر',
      }
    ]
  },
  {
    name: 'malaysia',
    iso_code: 'MY',
    phone_country: '+60',
    flag: '/flags/malaysia.svg',
    max: 9,
    min: 7,
    translation: [
      {
        es: 'Malasia',
        sv: 'Malaysia',
        de: 'Malaysia',
        fr: 'Malaisie',
        ar: 'ماليزيا',
      }
    ]
  },
  {
    name: 'malawi',
    iso_code: 'MW',
    phone_country: '+265',
    flag: '/flags/malawi.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Malawi',
        sv: 'Malawi',
        de: 'Malawi',
        fr: 'Malawi',
        ar: 'مالاوي',
      }
    ]
  },
  {
    name: 'mali',
    iso_code: 'ML',
    phone_country: '+223',
    flag: '/flags/mali.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Mali',
        sv: 'Mali',
        de: 'Mali',
        fr: 'Mali',
        ar: 'مالي',
      }
    ]
  },
  {
    name: 'malta',
    iso_code: 'MT',
    phone_country: '+356',
    flag: '/flags/malta.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Malta',
        sv: 'Malta',
        de: 'Malta',
        fr: 'Malte',
        ar: 'مالطا',
      }
    ]
  },
  {
    name: 'morocco',
    iso_code: 'MA',
    phone_country: '+212',
    flag: '/flags/morocco.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Marruecos',
        sv: 'Marocko',
        de: 'Marokko',
        fr: 'Maroc',
        ar: 'المغرب',
      }
    ]
  },
  {
    name: 'martinique',
    iso_code: 'MQ',
    phone_country: '+596',
    flag: '/flags/martinique.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Martinica',
        sv: 'Martinique',
        de: 'Martinique',
        fr: 'Martinique',
        ar: 'مارتينيك',
      }
    ]
  },
  {
    name: 'mauricio',
    iso_code: 'MU',
    phone_country: '+230',
    flag: '/flags/mauritius.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Mauricio',
        sv: 'Mauritius',
        de: 'Mauritius',
        fr: 'Île Maurice',
        ar: 'موريشيوس',
      }
    ]
  },
  {
    name: 'mauritania',
    iso_code: 'MR',
    phone_country: '+222',
    flag: '/flags/mauritania.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Mauritania',
        sv: 'Mauritania',
        de: 'Mauretanien',
        fr: 'Mauritanie',
        ar: 'موريتانيا',
      }
    ]
  },
  {
    name: 'micronesia',
    iso_code: 'FM',
    phone_country: '+691',
    flag: '/flags/micronesia.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Micronesia',
        sv: 'Mikronesien',
        de: 'Mikronesien',
        fr: 'Micronésie',
        ar: 'ميكرونيزيا',
      }
    ]
  },
  {
    name: 'moldova',
    iso_code: 'MD',
    phone_country: '+373',
    flag: '/flags/moldova.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Moldavia',
        sv: 'Moldavien',
        de: 'Moldawien',
        fr: 'Moldavie',
        ar: 'مولدوفا',
      }
    ]
  },
  {
    name: 'mongolia',
    iso_code: 'MN',
    phone_country: '+976',
    flag: '/flags/mongolia.svg',
    max: 8,
    min: 7,
    translation: [
      {
        es: 'Mongolia',
        sv: 'Mongoliet',
        de: 'Mongolei',
        fr: 'Mongolie',
        ar: 'منغوليا',
      }
    ]
  },
  {
    name: 'montenegro',
    iso_code: 'ME',
    phone_country: '+382',
    flag: '/flags/montenegro.svg',
    max: 12,
    min: 4,
    translation: [
      {
        es: 'Montenegro',
        sv: 'Montenegro',
        de: 'Montenegro',
        fr: 'Monténégro',
        ar: 'مونتينيغرو',
      }
    ]
  },
  {
    name: "myanmar_birmania",
    iso_code: 'MM',
    phone_country: '+95',
    flag: '/flags/myanmar.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Birmania',
        sv: 'Myanmar',
        de: 'Myanmar',
        fr: 'Birmanie',
        ar: 'ميانمار',
      }
    ]
  },
  {
    name: 'montserrat',
    iso_code: 'MS',
    phone_country: '+1-664',
    flag: '/flags/montserrat.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Montserrat',
        sv: 'Montserrat',
        de: 'Montserrat',
        fr: 'Montserrat',
        ar: 'مونتسيرات',
      }
    ]
  },
  {
    name: 'mozambique',
    iso_code: 'MZ',
    phone_country: '+258',
    flag: '/flags/mozambique.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Mozambique',
        sv: 'Moçambique',
        de: 'Mosambik',
        fr: 'Mozambique',
        ar: 'موزمبيق',
      }
    ]
  },  
  {
    name: 'namibia',
    iso_code: 'NA',
    phone_country: '+264',
    flag: '/flags/namibia.svg',
    max: 10,
    min: 6,
    translation: [
      {
        es: 'Namibia',
        sv: 'Namibia',
        de: 'Namibia',
        fr: 'Namibie',
        ar: 'ناميبيا',
      }
    ]
  },
  {
    name: 'nauru',
    iso_code: 'NR',
    phone_country: '+674',
    flag: '/flags/nauru.svg',
    max: 7,
    min: 4,
    translation: [
      {
        es: 'Nauru',
        sv: 'Nauru',
        de: 'Nauru',
        fr: 'Nauru',
        ar: 'ناورو',
      }
    ]
  },
  {
    name: 'nepal',
    iso_code: 'NP',
    phone_country: '+977',
    flag: '/flags/nepal.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Nepal',
        sv: 'Nepal',
        de: 'Nepal',
        fr: 'Népal',
        ar: 'نيبال',
      }
    ]
  },
  {
    name: 'nicaragua',
    iso_code: 'NI',
    phone_country: '+505',
    flag: '/flags/nicaragua.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Nicaragua',
        sv: 'Nicaragua',
        de: 'Nicaragua',
        fr: 'Nicaragua',
        ar: 'نيكاراغوا',
      }
    ]
  },
  {
    name: 'niger',
    iso_code: 'NE',
    phone_country: '+227',
    flag: '/flags/niger.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Níger',
        sv: 'Niger',
        de: 'Niger',
        fr: 'Niger',
        ar: 'النيجر',
      }
    ]
  },
  {
    name: 'nigeria',
    iso_code: 'NG',
    phone_country: '+234',
    flag: '/flags/nigeria.svg',
    max: 10,
    min: 7,
    translation: [
      {
        es: 'Nigeria',
        sv: 'Nigeria',
        de: 'Nigeria',
        fr: 'Nigéria',
        ar: 'نيجيريا',
      }
    ]
  },
  {
    name: 'niue',
    iso_code: 'NU',
    phone_country: '+683',
    flag: '/flags/niue.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Niue',
        sv: 'Niue',
        de: 'Niue',
        fr: 'Niue',
        ar: 'نيوي',
      }
    ]
  },
  {
    name: 'norway',
    iso_code: 'NO',
    phone_country: '+47',
    flag: '/flags/norway.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Noruega',
        sv: 'Norge',
        de: 'Norwegen',
        fr: 'Norvège',
        ar: 'النرويج',
      }
    ]
  },
  {
    name: 'new_zealand',
    iso_code: 'NZ',
    phone_country: '+64',
    flag: '/flags/new zealand.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Nueva Zelanda',
        sv: 'Nya Zeeland',
        de: 'Neuseeland',
        fr: 'Nouvelle-Zélande',
        ar: 'نيوزيلندا',
      }
    ]
  },
  {
    name: 'oman',
    iso_code: 'OM',
    phone_country: '+968',
    flag: '/flags/oman.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Omán',
        sv: 'Oman',
        de: 'Oman',
        fr: 'Oman',
        ar: 'عمان',
      }
    ]
  },
  {
    name: 'netherlands',
    iso_code: 'NL',
    phone_country: '+31',
    flag: '/flags/netherlands.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Países Bajos',
        sv: 'Nederländerna',
        de: 'Niederlande',
        fr: 'Pays-Bas',
        ar: 'هولندا',
      }
    ]
  },
  {
    name: 'pakistan',
    iso_code: 'PK',
    phone_country: '+92',
    flag: '/flags/pakistan.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Pakistán',
        sv: 'Pakistan',
        de: 'Pakistan',
        fr: 'Pakistan',
        ar: 'باكستان',
      }
    ]
  },
  {
    name: 'palau',
    iso_code: 'PW',
    phone_country: '+680',
    flag: '/flags/palau.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Palau',
        sv: 'Palau',
        de: 'Palau',
        fr: 'Palaos',
        ar: 'بالاو',
      }
    ]
  },  
  {
    name: 'palestine',
    iso_code: 'PS',
    phone_country: '+970',
    flag: '/flags/palestine.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Palestina',
        sv: 'Palestina',
        de: 'Palästina',
        fr: 'Palestine',
        ar: 'فلسطين',
      }
    ]
  },
  {
    name: 'panama',
    iso_code: 'PA',
    phone_country: '+507',
    flag: '/flags/panama.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Panamá',
        sv: 'Panama',
        de: 'Panama',
        fr: 'Panama',
        ar: 'بنما',
      }
    ]
  },
  {
    name: 'papua_new_guinea',
    iso_code: 'PG',
    phone_country: '+675',
    flag: '/flags/papua new guinea.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Papúa Nueva Guinea',
        sv: 'Papua Nya Guinea',
        de: 'Papua-Neuguinea',
        fr: 'Papouasie-Nouvelle-Guinée',
        ar: 'بابوا غينيا الجديدة',
      }
    ]
  },
  {
    name: 'paraguay',
    iso_code: 'PY',
    phone_country: '+595',
    flag: '/flags/paraguay.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Paraguay',
        sv: 'Paraguay',
        de: 'Paraguay',
        fr: 'Paraguay',
        ar: 'باراغواي',
      }
    ]
  },
  {
    name: 'peru',
    iso_code: 'PE',
    phone_country: '+51',
    flag: '/flags/peru.svg',
    max: 9,
    min: 8,
    translation: [
      {
        es: 'Perú',
        sv: 'Peru',
        de: 'Peru',
        fr: 'Pérou',
        ar: 'بيرو',
      }
    ]
  },
  {
    name: 'french_polynesia',
    iso_code: 'PF',
    phone_country: '+689',
    flag: '/flags/french polynesia.svg',
    max: 7,
    min: 7,
    translation: [
      {
        es: 'Polinesia Francesa',
        sv: 'Franska Polynesien',
        de: 'Französisch-Polynesien',
        fr: 'Polynésie française',
        ar: 'بولينيزيا الفرنسية',
      }
    ]
  },
  {
    name: 'poland',
    iso_code: 'PL',
    phone_country: '+48',
    flag: '/flags/poland.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Polonia',
        sv: 'Polen',
        de: 'Polen',
        fr: 'Pologne',
        ar: 'بولندا',
      }
    ]
  },
  {
    name: 'portugal',
    iso_code: 'PT',
    phone_country: '+351',
    flag: '/flags/portugal.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Portugal',
        sv: 'Portugal',
        de: 'Portugal',
        fr: 'Portugal',
        ar: 'البرتغال',
      }
    ]
  },
  {
    name: 'puerto_rico',
    iso_code: 'PR',
    phone_country: '+1-787',
    flag: '/flags/puerto rico.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Puerto Rico',
        sv: 'Puerto Rico',
        de: 'Puerto Rico',
        fr: 'Porto Rico',
        ar: 'بورتو ريكو',
      }
    ]
  },
  {
    name: 'qatar',
    iso_code: 'QA',
    phone_country: '+974',
    flag: '/flags/qatar.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Catar',
        sv: 'Katar',
        de: 'Katar',
        fr: 'Qatar',
        ar: 'قطر',
      }
    ]
  },
  {
    name: 'central_african_republic',
    iso_code: 'CF',
    phone_country: '+236',
    flag: '/flags/central african republic.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'República Centroafricana',
        sv: 'Centralafrikanska republiken',
        de: 'Zentralafrikanische Republik',
        fr: 'République centrafricaine',
        ar: 'جمهورية أفريقيا الوسطى',
      }
    ]
  },
  {
    name: 'czech_republic',
    iso_code: 'CZ',
    phone_country: '+420',
    flag: '/flags/czech republic.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'República Checa',
        sv: 'Tjeckien',
        de: 'Tschechien',
        fr: 'République tchèque',
        ar: 'جمهورية التشيك',
      }
    ]
  },
  {
    name: 'dominican_republic',
    iso_code: 'DO',
    phone_country: '+1-809',
    flag: '/flags/dominican republic.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'República Dominicana',
        sv: 'Dominikanska republiken',
        de: 'Dominikanische Republik',
        fr: 'République dominicaine',
        ar: 'جمهورية الدومينيكان',
      }
    ]
  },
  {
    name: 'republic_of_south_sudan',
    iso_code: 'SS',
    phone_country: '+211',
    flag: '/flags/south sudan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'República de Sudán del Sur',
        sv: 'Sydsudan',
        de: 'Südsudan',
        fr: 'République du Soudan du Sud',
        ar: 'جمهورية جنوب السودان',
      }
    ]
  },
  {
    name: 'rwanda',
    iso_code: 'RW',
    phone_country: '+250',
    flag: '/flags/rwanda.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Ruanda',
        sv: 'Rwanda',
        de: 'Ruanda',
        fr: 'Rwanda',
        ar: 'رواندا',
      }
    ]
  },
  {
    name: 'romania',
    iso_code: 'RO',
    phone_country: '+40',
    flag: '/flags/romania.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Rumanía',
        sv: 'Rumänien',
        de: 'Rumänien',
        fr: 'Roumanie',
        ar: 'رومانيا',
      }
    ]
  },  
  {
    name: 'russia',
    iso_code: 'RU',
    phone_country: '+7',
    flag: '/flags/russia.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Rusia',
        sv: 'Ryssland',
        de: 'Russland',
        fr: 'Russie',
        ar: 'روسيا',
      }
    ]
  },
  {
    name: 'samoa',
    iso_code: 'WS',
    phone_country: '+685',
    flag: '/flags/samoa.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Samoa',
        sv: 'Samoa',
        de: 'Samoa',
        fr: 'Samoa',
        ar: 'ساموا',
      }
    ]
  },
  {
    name: 'american_samoa',
    iso_code: 'AS',
    phone_country: '+1-684',
    flag: '/flags/american samoa.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Samoa Americana',
        sv: 'Amerikanska Samoa',
        de: 'Amerikanisch-Samoa',
        fr: 'Samoa américaines',
        ar: 'سماوات أمريكية',
      }
    ]
  },
  {
    name: 'san_marino',
    iso_code: 'SM',
    phone_country: '+378',
    flag: '/flags/san marino.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'San Marino',
        sv: 'San Marino',
        de: 'San Marino',
        fr: 'Saint-Marin',
        ar: 'سان مارينو',
      }
    ]
  },
  {
    name: 'senegal',
    iso_code: 'SN',
    phone_country: '+221',
    flag: '/flags/senegal.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Senegal',
        sv: 'Senegal',
        de: 'Senegal',
        fr: 'Sénégal',
        ar: 'السنغال',
      }
    ]
  },
  {
    name: 'serbia',
    iso_code: 'RS',
    phone_country: '+381',
    flag: '/flags/serbia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Serbia',
        sv: 'Serbien',
        de: 'Serbien',
        fr: 'Serbie',
        ar: 'صربيا',
      }
    ]
  },
  {
    name: 'seychelles',
    iso_code: 'SC',
    phone_country: '+248',
    flag: '/flags/seychelles.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Seychelles',
        sv: 'Seychellerna',
        de: 'Seychellen',
        fr: 'Seychelles',
        ar: 'سيشيل',
      }
    ]
  },
  {
    name: 'sierra_leone',
    iso_code: 'SL',
    phone_country: '+232',
    flag: '/flags/sierra leone.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Sierra Leona',
        sv: 'Sierra Leone',
        de: 'Sierra Leone',
        fr: 'Sierra Leone',
        ar: 'سيراليون',
      }
    ]
  },
  {
    name: 'singapore',
    iso_code: 'SG',
    phone_country: '+65',
    flag: '/flags/singapore.svg',
    max: 8,
    min: 8,
    translation: [
      {
        es: 'Singapur',
        sv: 'Singapore',
        de: 'Singapur',
        fr: 'Singapour',
        ar: 'سنغافورة',
      }
    ]
  },
  {
    name: 'sint_maarten',
    iso_code: 'SX',
    phone_country: '+1-721',
    flag: '/flags/sint maarten.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Sint Maarten',
        sv: 'Sint Maarten',
        de: 'Sint Maarten',
        fr: 'Sint Maarten',
        ar: 'سينت مارتن',
      }
    ]
  },
  {
    name: 'syria',
    iso_code: 'SY',
    phone_country: '+963',
    flag: '/flags/syria.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Siria',
        sv: 'Syrien',
        de: 'Syrien',
        fr: 'Syrie',
        ar: 'سوريا',
      }
    ]
  },
  {
    name: 'somalia',
    iso_code: 'SO',
    phone_country: '+252',
    flag: '/flags/somalia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Somalia',
        sv: 'Somalia',
        de: 'Somalia',
        fr: 'Somalie',
        ar: 'الصومال',
      }
    ]
  },
  {
    name: 'sri_lanka',
    iso_code: 'LK',
    phone_country: '+94',
    flag: '/flags/sri lanka.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Sri Lanka',
        sv: 'Sri Lanka',
        de: 'Sri Lanka',
        fr: 'Sri Lanka',
        ar: 'سريلانكا',
      }
    ]
  },
  {
    name: 'south_africa',
    iso_code: 'ZA',
    phone_country: '+27',
    flag: '/flags/south africa.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Sudáfrica',
        sv: 'Sydafrika',
        de: 'Südafrika',
        fr: 'Afrique du Sud',
        ar: 'جنوب أفريقيا',
      }
    ]
  },
  {
    name: 'sudan',
    iso_code: 'SD',
    phone_country: '+249',
    flag: '/flags/sudan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Sudán',
        sv: 'Sudan',
        de: 'Sudan',
        fr: 'Soudan',
        ar: 'السودان',
      }
    ]
  },
  {
    name: 'switzerland',
    iso_code: 'CH',
    phone_country: '+41',
    flag: '/flags/switzerland.svg',
    max: 12,
    min: 4,
    translation: [
      {
        es: 'Suiza',
        sv: 'Schweiz',
        de: 'Schweiz',
        fr: 'Suisse',
        ar: 'سويسرا',
      }
    ]
  },
  {
    name: 'suriname',
    iso_code: 'SR',
    phone_country: '+597',
    flag: '/flags/suriname.svg',
    max: 7,
    min: 6,
    translation: [
      {
        es: 'Surinam',
        sv: 'Surinam',
        de: 'Suriname',
        fr: 'Suriname',
        ar: 'سورينام',
      }
    ]
  },
  {
    name: 'swaziland',
    iso_code: 'SZ',
    phone_country: '+268',
    flag: '/flags/swaziland.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Suazilandia',
        sv: 'Swaziland',
        de: 'Swasiland',
        fr: 'Swaziland',
        ar: 'سوازيلاند',
      }
    ]
  },
  {
    name: 'tajikistan',
    iso_code: 'TJ',
    phone_country: '+992',
    flag: '/flags/tajikistan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tayikistán',
        sv: 'Tadzjikistan',
        de: 'Tadschikistan',
        fr: 'Tadjikistan',
        ar: 'طاجيكستان',
      }
    ]
  },
  {
    name: 'thailand',
    iso_code: 'TH',
    phone_country: '+66',
    flag: '/flags/thailand.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tailandia',
        sv: 'Thailand',
        de: 'Thailand',
        fr: 'Thaïlande',
        ar: 'تايلاند',
      }
    ]
  },
  {
    name: 'taiwan',
    iso_code: 'TW',
    phone_country: '+886',
    flag: '/flags/taiwan.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Taiwán',
        sv: 'Taiwan',
        de: 'Taiwan',
        fr: 'Taïwan',
        ar: 'تايوان',
      }
    ]
  },
  {
    name: 'tanzania',
    iso_code: 'TZ',
    phone_country: '+255',
    flag: '/flags/tanzania.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tanzania',
        sv: 'Tanzania',
        de: 'Tansania',
        fr: 'Tanzanie',
        ar: 'تنزانيا',
      }
    ]
  },
  {
    name: 'british_indian_ocean_territory',
    iso_code: 'IO',
    phone_country: '+246',
    flag: '/flags/british indian ocean territory.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Territorio Británico del Océano Índico',
        sv: 'Brittiska Indiska Oceanen territorium',
        de: 'Britisches Territorium im Indischen Ozean',
        fr: 'Territoire britannique de l’océan Indien',
        ar: 'الإقليم البريطاني في المحيط الهندي',
      }
    ]
  },
  {
    name: 'east_timor',
    iso_code: 'TL',
    phone_country: '+670',
    flag: '/flags/east-timor.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Timor Oriental',
        sv: 'Östtimor',
        de: 'Osttimor',
        fr: 'Timor oriental',
        ar: 'تيمور الشرقية',
      }
    ]
  },
  {
    name: 'togo',
    iso_code: 'TG',
    phone_country: '+228',
    flag: '/flags/togo.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Togo',
        sv: 'Togo',
        de: 'Togo',
        fr: 'Togo',
        ar: 'توغو',
      }
    ]
  },
  {
    name: 'tokelau',
    iso_code: 'TK',
    phone_country: '+690',
    flag: '/flags/tokelau.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tokelau',
        sv: 'Tokelau',
        de: 'Tokelau',
        fr: 'Tokelau',
        ar: 'توكيلاو',
      }
    ]
  },
  {
    name: 'tonga',
    iso_code: 'TO',
    phone_country: '+676',
    flag: '/flags/tonga.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tonga',
        sv: 'Tonga',
        de: 'Tonga',
        fr: 'Tonga',
        ar: 'تونغا',
      }
    ]
  },
  {
    name: 'trinidad_and_tobago',
    iso_code: 'TT',
    phone_country: '+1-868',
    flag: '/flags/trinidad and tobago.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Trinidad y Tobago',
        sv: 'Trinidad och Tobago',
        de: 'Trinidad und Tobago',
        fr: 'Trinité-et-Tobago',
        ar: 'ترينيداد وتوباغو',
      }
    ]
  },
  {
    name: 'tunisia',
    iso_code: 'TN',
    phone_country: '+216',
    flag: '/flags/tunisia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Túnez',
        sv: 'Tunisien',
        de: 'Tunesien',
        fr: 'Tunisie',
        ar: 'تونس',
      }
    ]
  },
  {
    name: 'turkmenistan',
    iso_code: 'TM',
    phone_country: '+993',
    flag: '/flags/turkmenistan.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Turkmenistán',
        sv: 'Turkmenistan',
        de: 'Turkmenistan',
        fr: 'Turkménistan',
        ar: 'تركمانستان',
      }
    ]
  },
  {
    name: 'turkey',
    iso_code: 'TR',
    phone_country: '+90',
    flag: '/flags/turkey.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Turquía',
        sv: 'Turkiet',
        de: 'Türkei',
        fr: 'Turquie',
        ar: 'تركيا',
      }
    ]
  },
  {
    name: 'tuvalu',
    iso_code: 'TV',
    phone_country: '+688',
    flag: '/flags/tuvalu.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Tuvalu',
        sv: 'Tuvalu',
        de: 'Tuvalu',
        fr: 'Tuvalu',
        ar: 'توفالو',
      }
    ]
  },
  {
    name: 'ukraine',
    iso_code: 'UA',
    phone_country: '+380',
    flag: '/flags/ukraine.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Ucrania',
        sv: 'Ukraina',
        de: 'Ukraine',
        fr: 'Ukraine',
        ar: 'أوكرانيا',
      }
    ]
  },
  {
    name: 'uganda',
    iso_code: 'UG',
    phone_country: '+256',
    flag: '/flags/uganda.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Uganda',
        sv: 'Uganda',
        de: 'Uganda',
        fr: 'Ouganda',
        ar: 'أوغندا',
      }
    ]
  },
  {
    name: 'uruguay',
    iso_code: 'UY',
    phone_country: '+598',
    flag: '/flags/uruguay.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Uruguay',
        sv: 'Uruguay',
        de: 'Uruguay',
        fr: 'Uruguay',
        ar: 'أوروغواي',
      }
    ]
  },
  {
    name: 'uzbekistan',
    iso_code: 'UZ',
    phone_country: '+998',
    flag: '/flags/uzbekistán.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Uzbekistán',
        sv: 'Usbekistan',
        de: 'Usbekistan',
        fr: 'Ouzbékistan',
        ar: 'أوزبكستان',
      }
    ]
  },
  {
    name: 'vanuatu',
    iso_code: 'VU',
    phone_country: '+678',
    flag: '/flags/vanuatu.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Vanuatu',
        sv: 'Vanuatu',
        de: 'Vanuatu',
        fr: 'Vanuatu',
        ar: 'فانواتو',
      }
    ]
  },
  {
    name: 'venezuela',
    iso_code: 'VE',
    phone_country: '+58',
    flag: '/flags/venezuela.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Venezuela',
        sv: 'Venezuela',
        de: 'Venezuela',
        fr: 'Venezuela',
        ar: 'فنزويلا',
      }
    ]
  },
  {
    name: 'vietnam',
    iso_code: 'VN',
    phone_country: '+84',
    flag: '/flags/vietnam.svg',
    max: 10,
    min: 10,
    translation: [
      {
        es: 'Vietnam',
        sv: 'Vietnam',
        de: 'Vietnam',
        fr: 'Vietnam',
        ar: 'فيتنام',
      }
    ]
  },
  {
    name: 'yemen',
    iso_code: 'YE',
    phone_country: '+967',
    flag: '/flags/yemen.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Yemen',
        sv: 'Jemen',
        de: 'Jemen',
        fr: 'Yémen',
        ar: 'اليمن',
      }
    ]
  },
  {
    name: 'djibouti',
    iso_code: 'DJ',
    phone_country: '+253',
    flag: '/flags/djibouti.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Yibuti',
        sv: 'Djibouti',
        de: 'Dschibuti',
        fr: 'Djibouti',
        ar: 'جيبوتي',
      }
    ]
  },
  {
    name: 'zambia',
    iso_code: 'ZM',
    phone_country: '+260',
    flag: '/flags/zambia.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Zambia',
        sv: 'Zambia',
        de: 'Sambia',
        fr: 'Zambie',
        ar: 'زامبيا',
      }
    ]
  },
  {
    name: 'zimbabwe',
    iso_code: 'ZW',
    phone_country: '+263',
    flag: '/flags/zimbabwe.svg',
    max: 9,
    min: 9,
    translation: [
      {
        es: 'Zimbabue',
        sv: 'Zimbabwe',
        de: 'Simbabwe',
        fr: 'Zimbabwe',
        ar: 'زيمبابوي',
      }
    ]
  }  
]
