<template>
  <ElConfigProvider :locale="elementPlusLanguage">
    <div class="div-container">
      <div class="not-found-wrapper">
        <div class="card-container">
          <div class="card-image">
            <img src="/images/error.jpg" />
          </div>
          <div class="card-title">
            <span class="heading2">
              {{ $t('uh_oh_we_cant_find_that_page') }}</span
            >
          </div>
          <div class="card-subtitle">
            <span class="body1">{{
              $t(
                'sorry_the_page_you_are_looking_for_doesnt_exist_or_has_been_moved'
              )
            }}</span>
          </div>
          <div class="card-action">
            <ElButton type="login" class="dark" @click="router.push('/')">
              {{ $t('take_me_home') }}
            </ElButton>
          </div>
        </div>
      </div>
    </div>
  </ElConfigProvider>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useFavicon } from '@vueuse/core'

definePageMeta({
  name: '404Page'
})

const { width } = useBreakpoints()
const router = useRouter()
const { $eventBus } = useNuxtApp()
const { locale } = useI18n({ useScope: 'global' })
const newColorScheme = ref('light')
const elementPlusLanguage = ref('en')

const favicon = computed(() => {
  return newColorScheme.value === 'dark' ? '/favicon.svg' : '/favicon-dark.svg'
})

watch(newColorScheme, () => {
  useFavicon(favicon.value, {
    rel: 'icon'
  })
})

const isUserLogged = computed(() => {
  return getAuthUser()?.value?.uuid ? true : false
})

onMounted(() => {
  setColorScheme()
  if (isUserLogged.value) {
    elementPlusLanguage.value = getAuthUser()?.value?.language.toLowerCase()
    locale.value = getAuthUser()?.value?.language.toLowerCase()
  } else {
    elementPlusLanguage.value =
      navigator.language.split('-')[0] === 'es' ? 'es' : 'en'
    locale.value = navigator.language.split('-')[0] === 'es' ? 'es' : 'en'
  }
})

const setColorScheme = () => {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    newColorScheme.value = 'dark'
  }
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (event) => {
      newColorScheme.value = event.matches ? 'dark' : 'light'
    })
}
</script>

<style scoped lang="scss">
.div-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh); /* Establece el mínimo del alto de la vista */
  height: 100vh;
  width: 100vw;
}

.not-found-wrapper {
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--brand-off-white);
  align-items: center;
  justify-content: center;
  .card-container {
    background-color: var(--brand-off-white);
    text-align: center;
    @media (min-width: 1100px) {
      width: 514px;
      height: 428px;
    }
    @media (max-width: 1100px) {
      padding: 0 20px;
    }
    .card-image {
      margin-bottom: 32px;
    }
    .card-title {
      margin-bottom: 16px;
    }
    .card-subtitle {
      margin-bottom: 32px;
    }
  }
}
</style>