import ALL_CUSTOMERS from '@/graphql/customers/query/getAllCustomersAutocomplete.gql'

const customers = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function customersAutocomplete(params: any) {
  const apolloQuery = ALL_CUSTOMERS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'UUID',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCustomersCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await customersAutocomplete({ search: queryString })
    cb(results?.getAllCustomers?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCustomersCb')
  }
}

export function getSearchCustomers(entityCustomer: any, entityCustomers: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCustomers('', entityCustomer, entityCustomers)
  }

  if (entityCustomer && !customers?.value?.find((k: any) => k.value === entityCustomer?.uuid)) {
    customers?.value.push({
      label: `${entityCustomer.user?.first_name ?? ''} ${entityCustomer.user?.last_name ?? ''}`,
      value: entityCustomer.uuid
    })
  }

  return customers?.value.filter((i: any) => i.label) ?? []
}

export async function searchCustomers(queryString: any, entityCustomer: any, entityCustomers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await customersAutocomplete({ search: queryString })
    const remoteCustomers = results?.getAllCustomers?.data?.map((i: any) => {
      return {
        label: `${i.user?.first_name ?? ''} ${i.user?.last_name ?? ''}`,
        value: i.uuid
      }
    }) ?? []
    const customersToAdd: { label: any; value: any }[] = []
    entityCustomers?.forEach((j: any) => {
      if (!remoteCustomers.find((k: any) => k.value === j.uuid)) {
        customersToAdd.push({
          label: `${j.user?.first_name ?? ''} ${j.user?.last_name ?? ''}`,
          value: j.uuid
        })
      }
    })
    if (entityCustomer && !remoteCustomers.find((k: any) => k.value === entityCustomer.uuid)) {
      customersToAdd.push({
        label: `${entityCustomer.user?.first_name ?? ''} ${entityCustomer.user?.last_name ?? ''}`,
        value: entityCustomer.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    customers.value = [...remoteCustomers, ...customersToAdd]

    firstTime.value = false

    if (entityCustomer) {
      getSearchCustomers(entityCustomer, entityCustomers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCustomers')
  }
}

export function getCustomerKey () {
  return key.value
}

export function initialCustomers () {
  customers.value = []
  key.value = 0
  firstTime.value = true
}