export function getSelectPopperOptions() {
    return {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: ({ placement, reference, popper }) => {
                        if (placement === 'bottom') {
                            return [0, 0]
                        } else if (placement === 'top') {
                            return [0, 0]
                        } else {
                            return []
                        }
                    }
                }
            }
        ]
    }
}