<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="24"
              style="max-height: 65vh; height: 65vh; overflow-y: auto"
            >
              <ElRow :gutter="20">
                <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <AvatarAddAvatar
                    :entity="form"
                    :file="form.mainImage"
                    @mainImageUploaded="mainImageUploaded"
                  />
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="name" :label="$t('name')">
                    <GMapAutocomplete
                      id="nameGoogleAutoComplete"
                      class="googleAutoComplete pac-target-input"
                      placeholder=""
                      :class="formattedAddressInvalid ? 'invalid' : ''"
                      @place_changed="
                        ;(address = setGeolocation($event)),
                          updateAddress(address)
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="type" :label="$t('type')">
                    <ElSelect
                      v-model="form.type"
                      style="width: 100%"
                      :placeholder="$t('select_type')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in locationTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol
                  :span="containerWidth <= 700 ? 24 : 12"
                  :lg="12"
                  v-if="form.type === 'PLACE_OF_INTEREST'"
                >
                  <ElFormItem prop="subtype" :label="$t('subtype')">
                    <ElSelect
                      v-model="form.subtype"
                      style="width: 100%"
                      :placeholder="$t('select_an_option')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in locationSubtypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="formatted_address"
                    :label="$t('address')"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  >
                    <GMapAutocomplete
                      id="googleAutoComplete"
                      class="googleAutoComplete pac-target-input"
                      :class="formattedAddressInvalid ? 'invalid' : ''"
                      v-model="form.formatted_address"
                      @place_changed="
                        ;(address = setGeolocation($event)),
                          updateAddress(address)
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="street" :label="$t('street_and_number')">
                    <ElInput
                      v-model="form.street"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="city" :label="$t('city')">
                    <ElInput
                      v-model="form.city"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="postal_code" :label="$t('postal_code')">
                    <ElInput
                      v-model="form.postal_code"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="country_uuid"
                    :label="$t('country')"
                    class="autocomplete-new-entity"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  >
                    <el-select-v2
                      :key="getCountryKey()"
                      v-loading="getCountryKey() === 0"
                      v-model="form.country_uuid"
                      :remote-method="searchCountries"
                      :placeholder="$t('select_a_country')"
                      remote
                      filterable
                      autocomplete
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchCountries(t)"
                      style="width: 100%"
                      @change="updateProvinceArray($event)"
                    />
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewCountryDialog = true"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="province_uuid"
                    :label="$t('province')"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                    class="autocomplete-new-entity"
                  >
                    <ElSelect
                      v-model="form.province_uuid"
                      :placeholder="$t('select_a_province')"
                      :autocomplete="true"
                      style="width: 100%"
                      class="no-multiple"
                      :no-data-text="$t('select_first_a_country')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in provinces"
                        :key="item.uuid"
                        :label="item.name"
                        :value="item.uuid"
                      />
                    </ElSelect>
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewProvinceDialog = true" 
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="language"
                    :label="$t('location_languages')"
                    @keyup.enter="submit(ruleFormRef)"
                  >
                    <ElSelect
                      v-model="form.language"
                      :autocomplete="true"
                      :filterable="true"
                      :multiple="true"
                      style="width: 100%"
                      :placeholder="$t('select_one_or_more_langs')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option-group
                        v-for="(group, index) in locationLanguages"
                        :key="index"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-option-group>
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="currency" :label="$t('currency')">
                    <ElSelect
                      v-model="form.currency"
                      :autocomplete="true"
                      :filterable="true"
                      style="width: 100%"
                      :placeholder="$t('select_currency')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="max_winter_temperature"
                    :label="$t('max_winter_temperature')"
                  >
                    <ElInputNumber
                      v-model="form.max_winter_temperature"
                      type="number"
                      step="1"
                      @keyup.enter="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="min_winter_temperature"
                    :label="$t('min_winter_temperature')"
                  >
                    <ElInputNumber
                      v-model="form.min_winter_temperature"
                      type="number"
                      step="1"
                      @keyup.enter="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="max_summer_temperature"
                    :label="$t('max_summer_temperature')"
                  >
                    <ElInputNumber
                      v-model="form.max_summer_temperature"
                      type="number"
                      step="1"
                      @keyup.enter="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="min_summer_temperature"
                    :label="$t('min_summer_temperature')"
                  >
                    <ElInputNumber
                      v-model="form.min_summer_temperature"
                      type="number"
                      step="1"
                      @keyup.enter="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="timezone" :label="$t('timezone')">
                    <ElSelect
                      v-model="form.timezone"
                      :autocomplete="true"
                      :filterable="true"
                      style="width: 100%"
                      :placeholder="$t('select_timezone')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <el-option
                        v-for="item in timezones"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol
                  :span="containerWidth <= 700 ? 24 : 12"
                  :lg="12"
                  v-if="form.type !== 'PLACE_OF_INTEREST'"
                />
              </ElRow>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewProvinceDialog"
      :width="containerWidth <= 600 ? '100%' : '400px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_province') }}
        </span>
      </template>
      <QuickCreateProvinceDialog
        @create=";(showNewProvinceDialog = false), createProvince($event)"
        @cancel="showNewProvinceDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCountryDialog"
      :width="containerWidth <= 600 ? '100%' : '400px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_country') }}
        </span>
      </template>
      <QuickCreateCountryDialog
        @create=";(showNewCountryDialog = false), createCountry($event)"
        @cancel="showNewCountryDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_LOCATION from '~/graphql/locations/mutation/createLocation.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const streetElForm = (rule, value, callback) => {
  if (form.type === 'PLACE_OF_INTEREST' && !form.street) {
    return callback(t('street_is_required'))
  }
  return callback()
}

const cityElForm = (rule, value, callback) => {
  if (form.type === 'PLACE_OF_INTEREST' && !form.city) {
    return callback(t('city_is_required'))
  }
  return callback()
}

const form = reactive({
  name: '',
  type: '',
  formatted_address: '',
  street: '',
  city: '',
  postal_code: '',
  province_uuid: '',
  country_uuid: '',
  country_name: '',
  min_winter_temperature: 0,
  max_winter_temperature: 0,
  min_summer_temperature: 0,
  max_summer_temperature: 0,
  google_provider_id: '',
  timezone: '',
  currency: 'eur',
  latitude: '',
  longitude: '',
  language: '',
  main_image_id: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewProvinceDialog = ref(false)
const showNewCountryDialog = ref(false)
let {
  provinces,
  countriesArray,
  address,
  containerWidth,
  formattedAddressInvalid
} = getFormInitialVariables()

const rules = {
  name: { required },
  type: { required },
  formatted_address: { required },
  street: {
    required: requiredIf(function () {
      return form.type === 'PLACE_OF_INTEREST' && !form.street
    })
  },
  city: {
    required: requiredIf(function () {
      return form.type === 'PLACE_OF_INTEREST' && !form.city
    })
  }
}

const locationLanguages = getLocationLanguages()
const locationTypes = getLocationTypes()
const locationSubtypes = getLocationSubtypes()
const timezones = getTimezones()
const currencies = getCurrencies()
const v$ = useVuelidate(rules, form)

onMounted(() => {
  initialCountries()
})

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  type: [
    {
      required: true,
      message: t('type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  formatted_address: [
    {
      required: true,
      message: t('address_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  street: [{ validator: streetElForm, trigger: ['blur', 'change'] }],
  city: [{ validator: cityElForm, trigger: ['blur', 'change'] }]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    if (!form.name || !form.formatted_address) {
      formattedAddressInvalid.value = true
    } else {
      formattedAddressInvalid.value = false
    }
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  let variables = {
    name: form.name,
    type: form.type,
    subtype: form.type === 'PLACE_OF_INTEREST' ? form.subtype : null,
    formatted_address: form.formatted_address,
    street: form.street,
    city: form.city,
    postal_code: form.postal_code,
    province_uuid: form.province_uuid,
    country_uuid: form.country_uuid,
    latitude: form.latitude,
    longitude: form.longitude,
    currency: form.currency,
    language: form.language,
    timezone: form.timezone ? form.timezone : 'Europe/Madrid',
    google_provider_id: form.google_provider_id,
    temperature: {
      winter: {
        min: form.min_winter_temperature,
        max: form.max_winter_temperature
      },
      summer: {
        min: form.min_summer_temperature,
        max: form.max_summer_temperature
      }
    }
  }
  if (form.main_image_id) {
    variables.main_image_id = form.main_image_id
  }
  const response = await mutation(CREATE_LOCATION, variables)
  if (response.error) {
    loading.value = false
    if (response.error.message.includes('input.name')) {
      $showError(t('location_name_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'createLocation')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createLocation ?? null)
    loading.value = false
  }
}

const updateAddress = async (address) => {
  if (address?.find((i) => i.field === 'name')?.value) {
    form.name = address.find((i) => i.field === 'name').value
    setGoogleAutocompleteInput(form, 'nameGoogleAutoComplete', 'name')
  }
  if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form.formatted_address = address.find(
      (i) => i.field === 'formatted_address'
    ).value
    setGoogleAutocompleteInput(form)
  }
  if (address?.find((i) => i.field === 'street')?.value) {
    form.street = address.find((i) => i.field === 'street').value
  }
  if (address?.find((i) => i.field === 'city')?.value) {
    form.city = address.find((i) => i.field === 'city').value
  }
  if (address?.find((i) => i.field === 'postal_code')?.value) {
    form.postal_code = address.find((i) => i.field === 'postal_code').value
  }
  if (address?.find((i) => i.field === 'province')?.value) {
    form.province_name = address.find((i) => i.field === 'province').value
  }
  if (address?.find((i) => i.field === 'country')?.value) {
    form.country_name = address.find((i) => i.field === 'country').value
    form.country_code = address.find((i) => i.field === 'country_code').value
    searchCountriesByCode(form.country_name)
    countriesArray = await getSearchCountriesByCode()
    if (countriesArray?.length) {
      form.country_uuid =
        countriesArray.find((i) => i.code === form.country_code)?.value ?? null
      if (form.country_uuid) {
        provinces.value = await searchProvincesByCountry(form.country_uuid)
        if (address?.find((i) => i.field === 'province')?.value) {
          form.province_name = address.find((i) => i.field === 'province').value
          form.province_uuid =
            provinces.value?.find((i) => i.name === form.province_name)?.uuid ??
            null
          if (!form.province_uuid) {
            createAsyncProvince(address)
          }
        }
      } else {
        getCountryAndProvinceByName(address)
      }
    } else {
      getCountryAndProvinceByName(address)
    }
  }
  if (address?.find((i) => i.field === 'latitude')?.value) {
    form.latitude = address.find((i) => i.field === 'latitude').value
  }
  if (address?.find((i) => i.field === 'longitude')?.value) {
    form.longitude = address.find((i) => i.field === 'longitude').value
  }
  if (address?.find((i) => i.field === 'place_id')?.value) {
    form.google_provider_id = address.find((i) => i.field === 'place_id').value
  }
}
const getCountryAndProvinceByName = async (address) => {
  const provinceName = address.find((i) => i.field === 'province').value
  const province = await searchProvincesByName(provinceName)
  form.country_name = province?.country?.name
  form.country_code = province?.country?.code
  form.country_uuid = province?.country?.uuid
  if (form.country_code) {
    searchCountriesByCode(form.country_code)
    countriesArray = await getSearchCountriesByCode()
    provinces.value = await searchProvincesByCountry(form.country_uuid)
    form.province_name = address.find((i) => i.field === 'province').value
    form.province_uuid =
      provinces.value?.find((i) => i.name === form.province_name)?.uuid ?? null
  } else {
    createAsyncCountry(address)
  }
}
const updateProvinceArray = async (event) => {
  provinces.value = await searchProvincesByCountry(event)
}

const updateCountryAndProvince = async (event) => {
  searchCountries(event.name)
  provinces.value = await searchProvincesByCountry(event.uuid)
}

const createAsyncProvince = async (address) => {
  const provinceName =
    address.find((i) => i.field === 'province')?.value ?? null
  if (provinceName && form.country_uuid) {
    const province = await mutationProvince({
      name: provinceName,
      country_uuid: form.country_uuid
    })
    form.province_uuid = province.uuid
    form.province_name = province.name
    form.country_uuid = province.country?.uuid
    form.country_code = province.country?.code
    form.country_name = province.country?.name
    updateCountryAndProvince(province.country)
  }
}
const createAsyncCountry = async (address) => {
  const countryName = address.find((i) => i.field === 'country')?.value ?? null
  const countryCode =
    address.find((i) => i.field === 'country_code')?.value ?? null
  if (countryName && countryCode) {
    let country = await mutationCountry(
      { name: countryName, code: countryCode },
      t
    )
    if (country) {
      form.country_uuid = country.uuid
      form.country_code = country.code
      form.country_name = country.name
      createAsyncProvince(address)
    } else {
      country = getSearchCountriesByCode()?.find((i) => i.code === countryCode)
      if (country) {
        form.country_uuid = country?.uuid
        form.country_code = country?.code
        form.country_name = country?.name
        createAsyncProvince(address)
      }
    }
  }
}

const createProvince = async (event) => {
  if (event?.uuid) {
    form.province_uuid = event.uuid
    form.province_name = event.name
    form.country_uuid = event.country?.uuid
    form.country_code = event.country?.code
    form.country_name = event.country?.name
    updateCountryAndProvince(event.country)
  }
}

const createCountry = async (event) => {
  if (event?.uuid) {
    form.country_uuid = event.uuid
    form.country_code = event.code
    form.country_name = event.name
    updateCountryAndProvince(event)
  }
}

const mainImageUploaded = (fileVariables) => {
  form.main_image_id = fileVariables.file
}
</script>
