const DNI_REGEX = /^(\d{8})([A-Z])$/
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/
const NIR_REGEX = /^(([0-9A-Z]{2}[0-9]{9})|([0-9A-Z]{15}))$/
const CNI_REGEX = /^([0-9A-Z]{12})$/

export function validateId (value: any, item: { document_type_country: any; cif_country: any }) {
  let valid = false
  if (!value) {
    return true
  }
  const nationality = (item)
    ? item.document_type_country
      ? item.document_type_country
      : item.cif_country
        ? item.cif_country
        : ''
    : ''

  if (nationality) {
    switch (nationality.toUpperCase()) {
      case 'ES':
        valid = spanishValidator(value)
        break
      case 'FR':
        valid = frenchValidator(value)
        break
      case 'OT':
        valid = otherValidator()
        break
      default:
        valid = spanishValidator(value)
        break
    }
  } else {
    valid = spanishValidator(value)
  }

  return valid
}

function spanishValidator (value: { toString: () => string }) {
  const str = value ? value.toString().toUpperCase() : ''

  if (!NIE_REGEX.test(str) && !DNI_REGEX.test(str) && !CIF_REGEX.test(str)) { return false }

  let valid = false
  const type = spanishIdType(str)

  switch (type) {
    case 'dni':
      valid = validDNI(str)
      break
    case 'nie':
      valid = validNIE(str)
      break
    case 'cif':
      valid = validCIF(str)
      break
  }
  return valid
}

function spanishIdType (str: string) {
  if (str.match(DNI_REGEX)) {
    return 'dni'
  }
  if (str.match(CIF_REGEX)) {
    return 'cif'
  }
  if (str.match(NIE_REGEX)) {
    return 'nie'
  }
}

function validDNI (dni: string) {
  const dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'
  const letter = dniLetters.charAt(parseInt(dni, 10) % 23)

  return letter === dni.charAt(8)
}

function validNIE (nie: string) {
  // Change the initial letter for the corresponding number and validate as DNI
  return nie.match(NIE_REGEX) ? true : false
}

function validCIF (cif: string) {
  let par = 0
  let non = 0
  const letras = 'ABCDEFGHJKLMNPQRSUVW'
  const letra = cif.charAt(0)

  if (cif.length !== 9) {
    // alert('El Cif debe tener 9 dígitos')
    return false
  }

  if (!letras.includes(letra.toUpperCase())) {
    // alert('El comienzo del Cif no es válido')
    return false
  }

  for (let zz = 2; zz < 8; zz += 2) {
    par = par + parseInt(cif.charAt(zz))
  }

  for (let zz = 1; zz < 9; zz += 2) {
    let nn = 2 * parseInt(cif.charAt(zz))
    if (nn > 9) { nn = 1 + (nn - 10) }
    non = non + nn
  }

  const parcial = par + non
  let control = 10 - (parcial % 10)
  if (control === 10) { control = 0 }

  if (!isNaN(parseInt(cif.charAt(8))) && control !== parseInt(cif.charAt(8))) {
    // alert('El Cif no es válido')
    return false
  }
  // alert('El Cif es válido')
  return true
}

function frenchValidator (str: string) {
  if (NIR_REGEX.test(str)) {
    return true
  }
  if (CNI_REGEX.test(str)) {
    const cleanedInput = str.toUpperCase().replace(/\s+/g, '')
    const key = cleanedInput.slice(-2)
    const withoutKey = cleanedInput.slice(0, -2)
    const { withoutLetters, offset } = (withoutKey.includes('A'))
      ? {
        withoutLetters: withoutKey.replace('A', '0'),
        offset: 1000000
      }
      : (withoutKey.includes('B'))
        ? {
          withoutLetters: withoutKey.replace('B', '0'),
          offset: 2000000
        }
        : {
          withoutLetters: withoutKey,
          offset: 0
        }
    const parse = parseInt(withoutLetters) - offset
    const computedKey = 97 - parse % 97

    return Number(computedKey) === Number(key)
  }
  return false
}

function otherValidator () {
  return true
}
