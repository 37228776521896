export const locationTypes = [
    {
      name: 'PLACE_OF_INTEREST'
    },
    {
      name: 'CITY'
    },
    {
      name: 'PROVINCE'
    },
    {
      name: 'REGION'
    },
    {
      name: 'COUNTRY'
    }
  ]
  