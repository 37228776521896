import { useI18n } from 'vue-i18n'
import { signingReasons } from '@/models/signing-reasons'

export function getSigningReasons () {
  const { t } = useI18n()
  return signingReasons.map((type: any) => {
    return {
      label: t(type.toLowerCase()),
      value: type.toUpperCase()
    }
  })
}
