export const currencies = [
  {
    "label": "euro",
    "value": "EUR",
    "symbol": "€"
  },
  {
    "label": "us_dollar",
    "value": "USD",
    "symbol": "$"
  }/* ,
  {
    "label": "japanese_yen",
    "value": "JPY",
    "symbol": "¥"
  },
  {
    "label": "pound_sterling",
    "value": "GBP",
    "symbol": "£"
  },
  {
    "label": "swiss_franc",
    "value": "CHF",
    "symbol": "CHF"
  },
  {
    "label": "canadian_dollar",
    "value": "CAD",
    "symbol": "$"
  },
  {
    "label": "australian_dollar",
    "value": "AUD",
    "symbol": "$"
  },
  {
    "label": "chinese_yuan",
    "value": "CNY",
    "symbol": "¥"
  },
  {
    "label": "russian_ruble",
    "value": "RUB",
    "symbol": "₽"
  },
  {
    "label": "brazilian_real",
    "value": "BRL",
    "symbol": "R$"
  },
  {
    "label": "mexican_peso",
    "value": "MXN",
    "symbol": "$"
  },
  {
    "label": "argentine_peso",
    "value": "ARS",
    "symbol": "$"
  },
  {
    "label": "chilean_peso",
    "value": "CLP",
    "symbol": "$"
  },
  {
    "label": "colombian_peso",
    "value": "COP",
    "symbol": "$"
  },
  {
    "label": "south_african_rand",
    "value": "ZAR",
    "symbol": "R"
  },
  {
    "label": "swedish_krona",
    "value": "SEK",
    "symbol": "kr"
  },
  {
    "label": "new_zealand_dollar",
    "value": "NZD",
    "symbol": "$"
  },
  {
    "label": "singapore_dollar",
    "value": "SGD",
    "symbol": "$"
  },
  {
    "label": "south_korean_won",
    "value": "KRW",
    "symbol": "₩"
  },
  {
    "label": "hungarian_forint",
    "value": "HUF",
    "symbol": "Ft"
  },
  {
    "label": "norwegian_krone",
    "value": "NOK",
    "symbol": "kr"
  },
  {
    "label": "hong_kong_dollar",
    "value": "HKD",
    "symbol": "$"
  },
  {
    "label": "taiwanese_dollar",
    "value": "TWD",
    "symbol": "$"
  } */
]
