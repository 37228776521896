import FIND_PROVINCE_BY_COUNTRY from '@/graphql/provinces/query/findProvincesByCountry.gql'

export function apolloProvincesByCountry(uuid: any) {
    return new Promise(async (resolve, reject) => {
        const result = await query(FIND_PROVINCE_BY_COUNTRY, { country_uuid: uuid }, { fetchPolicy: 'network-only' })

        resolve(result?.findProvincesByCountry ?? [])
    })
}

export async function searchProvincesByCountry(event: any) {
    if (event) {
        const provinces = await apolloProvincesByCountry(event)
        return provinces
    } else {
        return []
    }
}
