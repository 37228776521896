import { documentTypes } from '@/models/document-types'

export function getDocumentTypes () {
  return documentTypes.map((documentType) => {
    return {
      label: documentType.name,
      value: documentType.name,
      iso_code: documentType.iso_code
    }
  })
}