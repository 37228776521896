export const creditCardBrandTypes = {
  colors: {
    master: 'primary',
    visa: 'primary',
    verve: 'primary',
    discover: 'primary',
    american_express: 'primary',
    diners_club: 'primary',
    jcb: 'primary',
    others: 'primary',
    invalid: 'primary'
  },
  types: [
    'master',
    'visa',
    'verve',
    'discover',
    'american_express',
    'diners_club',
    'jcb',
    'others',
    'invalid'
  ]
}
