import { useI18n } from 'vue-i18n'
import { creditCardPaymentStatuses } from '@/models/credit-card-payment-statuses'

export function getCreditCardPaymentStatus () {
  const { t } = useI18n()
  return creditCardPaymentStatuses.statuses.map((status: any) => {
    return {
      label: t(`credit_card_payment_status.${status}`),
      value: status.toUpperCase()
    }
  })
}
