<template>
  <el-sub-menu :index="customKey" class="base-item-group border-subgroup">
    <template #title>
      <ElIcon>
        <span
          v-if="item.icon && item.icon.startsWith('mdi')"
          class="material-icons mr-2"
          style="font-size: 18px"
        >
          {{ item.icon.replace('mdi-', '') }}
        </span>
        <i
          v-else-if="item.icon && item.icon.startsWith('fa')"
          :class="`${item.icon}`"
          class="mr-2"
        />
      </ElIcon>
      <span class="truncate">{{ $t(item.title) }}</span>
    </template>
    <!-- eslint-disable-next-line vue/no-v-for-template-key -->
    <template v-for="(elem, key) in children" :key="key">
      <UtilsBaseItem
        v-if="!elem.children"
        class="bg-slate-100"
        :item="elem"
        :custom-key="`${customKey}-${key}`"
        :is-collapse="isCollapse"
        @changePath="$emit('changePath')"
      />
    </template>
  </el-sub-menu>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  customKey: {
    type: String,
    required: true
  }
})

const hasToBeSeen = (roles) => {
  if (!roles || roles.length === 0) {
    return true
  }
  return /* isUserRolesAllowed(roles) */ true
}

const children = computed(() => {
  return (
    props.item.children.map((item) => {
      return {
        ...item,
        to:
          item.to && props.item.group
            ? props.item.group + '/' + item.to
            : undefined
      }
    }) ?? []
  )
})
</script>

<style scoped>
.border-subgroup {
  box-shadow: inset 4px 0 0 0 transparent;
}
</style>
