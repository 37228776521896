import moment from 'moment'

export function fullFormatDate (value: any) {
  return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
}

export function fullFormatDateRaw (value: any) {
  return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
}

export function hourDateFormat (value: any) {
  return moment(String(value)).format('HH:mm - DD/MM/YYYY')
}

export function formatDate (value: any) {
  moment.locale(getAuthUser()?.value?.language)
  return moment(String(value)).format('DD/MM/YYYY')
}

export function formatDateRaw (value: any) {
  return moment(String(value)).format('YYYY-MM-DD')
}

export function formatHour (value: any) {
  return moment(String(value)).format('HH:mm:ss')
}

export function fullFormatDateWithoutSeconds (value: any) {
  return moment(String(value)).format('DD/MM/YYYY HH:mm')
}

export function formatHourWithoutSeconds (value: any) {
  return moment(String(value)).format('HH:mm')
}

export function textFormatDate (value: any, language: string | undefined) {
  moment.locale(language)
  return moment(String(value)).format('DD MMMM YYYY')
}
