export function clearLocalStorage() {
    let recentPages = []
    if (localStorage.getItem('recentPages')) {
      recentPages = JSON.parse(localStorage.getItem('recentPages'))
    }
    let topModules = []
    if (localStorage.getItem('topModules')) {
      topModules = JSON.parse(localStorage.getItem('topModules'))
    }
    localStorage.clear()
    localStorage.setItem('recentPages', JSON.stringify(recentPages))
    localStorage.setItem('topModules', JSON.stringify(topModules))
}