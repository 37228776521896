import { useI18n } from 'vue-i18n'
import { invoiceStatuses } from '@/models/invoice-statuses'

export function getInvoiceStatus () {
  const { t } = useI18n()
  return invoiceStatuses.statuses.map((status: any) => {
    return {
      label: t(`invoice_status.${status}`),
      value: status.toUpperCase(),
      color: invoiceStatuses.colors[status]
    }
  })
}
