export default [
  {
    group: '/profile',
    title: 'profile',
    icon: 'user',
    roles: ['Guide'],
    clickable: true,
    children: [
      {
        title: 'profile',
        icon: 'user',
        to: 'guide',
        roles: ['Guide'],
        clickable: true
      }
    ]
  },
  {
    group: '/guides',
    title: 'guides',
    icon: 'compass',
    roles: ['Guide'],
    clickable: false,
    children: [
      {
        title: 'tours',
        icon: 'globe-2',
        to: 'tours',
        roles: ['Guide'],
        clickable: false
      },
      {
        title: 'reservations',
        icon: 'calendar-person',
        to: 'reservations',
        roles: ['Guide'],
        clickable: false
      }
    ]
  },
  {
    group: '/passengers',
    title: 'passengers',
    icon: 'shopping-bag-2',
    roles: ['Guide'],
    clickable: false,
    children: [
      {
        title: 'passengers',
        icon: 'shopping-bag-2',
        to: 'passengers',
        roles: ['Guide'],
        clickable: false
      }
    ]
  }
]
