export const agentTypes = {
  colors: {
    activity: 'info',
    transport: 'primary',
    accommodation: 'secondary'
  },
  types: [
    'activity',
    'transport',
    'accommodation'
  ]
}
