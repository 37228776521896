const UPDATE_PASSWORD_ROUTE = 'auth-update-password'

export default defineNuxtRouteMiddleware((to, from) => {
  const user = getAuthUser()

  const passwordChangedAt = user?.value?.password_changed_at ?? null

  if (user?.value?.roles && userHasToChangePassword(user.value, passwordChangedAt, to)) {
    return navigateTo('/auth/update-password')
  }
})

function userHasToChangePassword (user: { roles: any[] }, passwordChangedAt: null, to: { name: string }) {
  return user && passwordChangedAt === null && !isUpdatePasswordRoute(to)
}

function isUpdatePasswordRoute (to: { name: string }) {
  return to.name === UPDATE_PASSWORD_ROUTE
}
