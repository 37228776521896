import { useI18n } from 'vue-i18n'
import { serialTypes } from '@/models/serial-types'

export function getSerialTypes () {
  const { t } = useI18n()
  return serialTypes.types.map((type: any) => {
    return {
      label: t(`serial_types.${type}`),
      value: type.toUpperCase()
    }
  })
}
