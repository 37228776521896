import { useI18n } from 'vue-i18n'
import { invoiceTypes } from '@/models/invoice-types'

export function getInvoiceTypes () {
  const { t } = useI18n()
  return invoiceTypes.types.map((type: any) => {
    return {
      label: t(`invoice_types.${type}`),
      value: type.toUpperCase()
    }
  })
}
