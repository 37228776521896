import { countries } from '@/models/countries'

const phoneNumberRegex = /\D/

export function validatePhoneNumberLength(value: any, item: any) {
    const itemToValidate = setPhoneItemFormat(item)

    if (!itemToValidate.phone_number && itemToValidate.phone_number_country) {
        return false
    }
    if (!itemToValidate.phone_number_country && itemToValidate.phone_number) {
        return false
    }
    if (itemToValidate.phone_number && phoneNumberRegex.test(itemToValidate.phone_number)) {
        return false
    }

    if (itemToValidate.phone_number && itemToValidate.phone_number_country) {
        const country = countries.find((country) => country.phone_country === itemToValidate.phone_number_country)
        let max = country?.max ?? 99999999
        let min = country?.min ?? 99999999

        if (itemToValidate.phone_number.length < min || itemToValidate.phone_number.length > max) {
            return false
        }
    }

    return true
}

function setPhoneItemFormat(item: any) {
    const result = {
        phone_number: '',
        phone_number_country: ''
    }

    if (item.hasOwnProperty('phone_number')) {
        result.phone_number = item.phone_number
    }
    if (item.hasOwnProperty('phone_number_country')) {
        result.phone_number_country = item.phone_number_country
    }
    if (item.hasOwnProperty('phone_country_code')) {
        result.phone_number_country = item.phone_country_code
    }
    if (item.hasOwnProperty('phone')) {
        result.phone_number = item.phone
    }
    if (item.hasOwnProperty('phone_country')) {
        result.phone_number_country = item.phone_country
    }
    if (item.hasOwnProperty('reservations_phone')) {
        result.phone_number = item.reservations_phone
    }
    if (item.hasOwnProperty('reservations_phone_country_code')) {
        result.phone_number_country = item.reservations_phone_country_code
    }
    if (item.hasOwnProperty('work_phone_number')) {
        result.phone_number = item.work_phone_number
    }
    if (item.hasOwnProperty('work_phone_number_country')) {
        result.phone_number_country = item.work_phone_number_country
    }
    if (item.hasOwnProperty('business_phone_number')) {
        result.phone_number = item.business_phone_number
    }
    if (item.hasOwnProperty('business_phone_number_country')) {
        result.phone_number_country = item.business_phone_number_country
    }
    if (item.hasOwnProperty('guide') && item.guide.hasOwnProperty('phone')) {
        result.phone_number = item.guide.phone
    }
    if (item.hasOwnProperty('guide') && item.guide.hasOwnProperty('phone_country')) {
        result.phone_number_country = item.guide.phone_country
    }
    if (item.hasOwnProperty('employee') && item.employee.hasOwnProperty('work_phone_number')) {
        result.phone_number = item.employee.work_phone_number
    }
    if (item.hasOwnProperty('employee') && item.employee.hasOwnProperty('work_phone_number_country')) {
        result.phone_number_country = item.employee.work_phone_number_country
    }

    if (result.phone_number) {
        result.phone_number = result.phone_number.replaceAll('-', '')
    }

    return result
}