import { useI18n } from 'vue-i18n'
import { creditCardBrandTypes } from '@/models/credit-card-brand-types'

export function getCreditCardBrandTypes () {
  const { t } = useI18n()
  return creditCardBrandTypes.types.map((type: any) => {
    return {
      label: t(`credit_card_brand_types.${type}`),
      value: type.toUpperCase()
    }
  })
}
