<template>
  <div class="menu">
    <div class="left" :class="`${!drawer ? 'not-drawer' : ''}`">
      <div class="logo-container" @click="router.push('/')">
        <div class="logo" :class="!drawer ? 'not-drawer' : ''">
          <img src="/logos/PALC_Marque_Black.svg" alt="logo" class="logo-img" />
          <div class="logo-title" v-if="width > 1100 && drawer">
            <span class="body1-strong">Paradise a La Carte</span>
          </div>
        </div>
      </div>
    </div>
    <div class="middle" :class="route.fullPath !== '/' ? 'not-home' : ''">
      <FullLayoutHeader v-if="width > 1100" />
    </div>
    <div class="right">
      <div
        class="el-dropdown"
        v-if="width > 1100 && !isGuide"
        @click="router.push('/configuration/users')"
      >
        <div class="settings-button">
          <Icons name="20px/gear" />
        </div>
      </div>
      <div
        class="el-dropdown"
        v-if="width <= 1100 && showSearch"
        @click="$emit('setSearchDrawer')"
      >
        <div
          class="search-button"
          :class="
            user && user.avatar && user.avatar.encode_file ? 'with-avatar' : ''
          "
        >
          <Icons name="24px/search" />
        </div>
      </div>
      <ElDropdown
        ref="dropdownNavbar1"
        trigger="click"
        v-if="width > 1100"
        popper-class="module-dropdown"
      >
        <div class="el-dropdown-link">
          <div class="dropdown-switcher-button">
            <Icons name="20px/elements" />
          </div>
        </div>
        <template #dropdown>
          <FullLayoutModuleDropdown
            @click="handleModule($event)"
            :module-selected="moduleSelected"
            @setModule="$emit('setModule', $event)"
          />
        </template>
      </ElDropdown>
      <ElDropdown
        ref="dropdownNavbar2"
        trigger="click"
        v-if="width > 1100"
        :hide-on-click="false"
        popper-class="avatar-dropdown"
        :popper-options="{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
        }"
      >
        <div class="el-dropdown-link">
          <ElAvatar
            :src="
              user && user.avatar && user.avatar.encode_file
                ? user.avatar.encode_file
                : ''
            "
            class="inline-block body1-strong"
            :size="42"
          >
            {{ initials }}
          </ElAvatar>
        </div>
        <template #dropdown>
          <el-dropdown-menu v-if="!showLanguagesMenu">
            <div class="avatar-container">
              <ElAvatar
                :src="
                  user && user.avatar && user.avatar.encode_file
                    ? user.avatar.encode_file
                    : ''
                "
                class="inline-block body1-strong"
                :size="42"
              >
                <span class="avatar-initials">{{ initials }}</span>
              </ElAvatar>
              <div class="avatar-name">
                <span
                  v-if="user && user.first_name && user.last_name"
                  class="body2-strong"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </span>
              </div>
            </div>
            <div
              v-for="(item, key) in menuItems"
              :key="key"
              class="avatar-item"
            >
              <el-dropdown-item
                v-if="item.title"
                :divided="item.divided"
                :class="item.class"
                @click="
                  item.action
                    ? $emit('click', { action: item.action, value: item.value })
                    : item.function
                    ? (showLanguagesMenu = true)
                    : null
                "
              >
                <Icons :name="item.icon" />
                <span class="body2-strong" :class="item.class"
                  >{{ item.title
                  }}<span v-if="item.image"> - <img :src="item.image" /></span
                ></span>
                <Icons
                  class="right-icon"
                  v-if="item.rightIcon"
                  :name="item.rightIcon"
                />
              </el-dropdown-item>
              <el-dropdown-item
                v-else-if="!item.title && (!isGuide && user?.employee)"
                :divided="item.divided"
                :class="item.class"
              >
                <FullLayoutSigningItem
                  :key="customSigningKey"
                  :value="user.employee"
                  @refetch="customSigningKey = setCustomKeyVariable()"
                />
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
          <el-dropdown-menu v-else @click="showLanguagesMenu = false">
            <div class="language-back">
              <el-dropdown-item class="language-back-item">
                <div>
                  <Icons name="16px/chevron_left" />
                  <div class="language-back-item">
                    <span class="body2-strong language">{{ t('back') }}</span>
                  </div>
                </div>
              </el-dropdown-item>
            </div>
            <div
              v-for="(item, key) in languages"
              :key="key"
              class="language-items"
            >
              <el-dropdown-item
                @click="
                  $emit('click', {
                    action: 'set_locale',
                    value: item.iso_code
                  })
                "
              >
                <img :src="item.flag" />&nbsp;-&nbsp;
                <span class="body2-strong">{{
                  item.label.split(' - ')[1]
                }}</span>
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </template>
      </ElDropdown>
      <ElAvatar
        class="inline-block body1-strong"
        :size="42"
        v-else
        :class="
          user && user.avatar && user.avatar.encode_file ? 'with-image' : ''
        "
        :src="
          user && user.avatar && user.avatar.encode_file
            ? user.avatar.encode_file
            : ''
        "
        @click="$emit('showAvatarDialog')"
      >
        {{ initials }}
      </ElAvatar>
      <div class="el-dropdown" v-if="width <= 1100" @click="$emit('setDrawer')">
        <div
          class="menu-button"
          :class="
            user && user.avatar && user.avatar.encode_file ? 'with-avatar' : ''
          "
        >
          <Icons name="24px/menu" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const { t } = useI18n()
const { width, height } = useBreakpoints()
const route = useRoute()
const router = useRouter()
const user = getAuthUser()
const roles = getAuthRoles()
const { $eventBus } = useNuxtApp()

const dropdownNavbar1 = ref()
const dropdownNavbar2 = ref()
const showLanguagesMenu = ref(false)
const languages = getLanguages()
const showSearch = ref(false)
const customSigningKey = ref(0)

const props = defineProps({
  drawer: {
    type: Boolean,
    default: true
  },
  showDrawerButton: {
    type: Boolean,
    required: false,
    default: false
  },
  user: {
    type: Object,
    required: false,
    default: () => {}
  },
  moduleSelected: {
    type: String,
    required: false,
    default: ''
  }
})

onMounted(() => {
  showLanguagesMenu.value = false
})

const selectedLanguage = computed(() => {
  return user?.value?.language
    ? languages
        ?.find(
          (i) => i.iso_code.toLowerCase() === user.value.language.toLowerCase()
        )
        ?.flag ?? '/flags/united kingdom.svg'
    : '/flags/united kingdom.svg'
})

const isGuide = computed(() => {
  if (roles?.value?.find((i) => i.name === 'Guide')) {
    return true
  }
  return false
})

const menuItems = ref([
  {
    title: '',
    icon: '',
    children: [],
    class: 'signing',
    divider: true
  },
  {
    title: `${t('language')}`,
    image: selectedLanguage.value,
    icon: '16px/globe',
    class: 'language',
    value: null,
    divided: isGuide.value ? false : true,
    children: [],
    rightIcon: '16px/chevron_right',
    function: 'showLanguagesMenu'
  },
  {
    title: t('update_password'),
    icon: '16px/lock',
    action: 'update_password',
    value: null,
    divided: false,
    children: []
  },
  {
    title: t('logout'),
    icon: '16px/log_out_3',
    action: 'logout',
    value: null,
    divided: true,
    children: []
  }
])

const initials = computed(() => {
  return props.user && props.user.first_name && props.user.last_name
    ? `${props.user.first_name
        .substring(0, 1)
        .toUpperCase()} ${props.user.last_name.substring(0, 1).toUpperCase()}`
    : ''
})

const handleModule = (e) => {
  if (e?.target?.innerText?.includes('g_translate')) {
    dropdownNavbar1?.value?.handleOpen()
  } else {
    dropdownNavbar1?.value?.handleClose()
  }
}

const goHome = () => {
  navigateTo('/')
}

onMounted(() => {
  $eventBus.$on('showSearch', ($event) => {
    showSearch.value = $event
  })
})
</script>

<style scoped lang="scss">
.menu {
  width: 100%;
  display: table;
  @media (min-width: 1100px) {
    height: 64px;
  }
  @media (max-width: 1100px) {
    height: 66px;
  }
}

.left {
  @media (min-width: 1100px) {
    height: 64px;
    display: table-cell;
    min-width: 224px;
    width: 224px;
    &.not-drawer {
      width: 65px !important;
      min-width: 65px !important;
    }
  }
  @media (max-width: 1100px) {
    width: 65px !important;
    height: 64px;
  }
  vertical-align: middle;
  .logo-container {
    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (min-width: 1100px) {
        justify-content: flex-start;
        height: 64px;
        width: 236px;
        padding-left: 16px;
        padding-right: 16px;
        border-right: 1px solid #f4f4f4;
        position: relative;
        left: -21px;
        &.not-drawer {
          width: 65px;
        }
      }
      @media (max-width: 1100px) {
        height: 66px;
        width: 100%;
      }
      .logo-title {
        display: inline-block;
      }

      .logo-img {
        @media (min-width: 1100px) {
          margin-right: 16px;
        }
        max-height: 50px;
      }
    }
  }
  .header-container {
    display: inline-block;
    height: 64px;
  }
}

.middle {
  display: table-cell;
  width: 80.7vw;
  @media (max-width: 1100px) {
    &:not(.not-home) {
      display: none !important;
    }
  }
}

.right {
  display: table-cell;
  width: 1px;
  vertical-align: middle;
  white-space: nowrap;
  .el-avatar {
    background-color: var(--brand-blue) !important;
    &.with-image {
      position: relative;
      top: 3px;
    }
  }
  .settings-button {
    height: 40px;
    width: 40px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .menu-button {
    @media (max-width: 1100px) {
      height: 42px;
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      margin-right: 0 !important;
      margin-left: 16px !important;
      &.with-avatar {
        top: 4px;
      }
    }
  }
  .search-button {
    @media (max-width: 1100px) {
      height: 42px;
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      margin-right: 16px !important;
      margin-left: 0 !important;
      &.with-avatar {
        top: 4px;
      }
    }
  }
  .dropdown-switcher-button {
    height: 40px;
    width: 40px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
