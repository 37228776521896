<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="business_name" :label="$t('name')">
                <ElInput
                  v-model="form.business_name"
                  @keyup.enter="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="cif" :label="$t('cif')">
                <ElInput v-model="form.cif" @keyup.enter="submit(ruleFormRef)">
                  <template #prepend>
                    <ElFormItem prop="cif_country">
                      <ElSelect
                        v-model="form.cif_country"
                        placeholder=""
                        filterable
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedCifCountry"
                            :src="selectedCifCountry.flag"
                          />
                        </template>
                        <ElOptionGroup
                          v-for="(group, index) in countries"
                          :key="index"
                        >
                          <ElOption
                            :class="
                              form.cif_country === item.iso_code
                                ? 'selected'
                                : ''
                            "
                            v-for="item in group.options"
                            label=""
                            :value="item.iso_code"
                            :key="item.iso_code"
                          >
                            <template #default>
                              <img :src="item.flag" :alt="item.iso_code" />
                              <span>{{ item.iso_code }}</span>
                            </template>
                          </ElOption>
                        </ElOptionGroup>
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_COMPANY from '~/graphql/companies/mutation/createCompany.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  business_name: '',
  cif: '',
  cif_country: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const countries = getModelCountries()

const rules = {
  business_name: { required },
  cif: { documentNumber }
}

const v$ = useVuelidate(rules, form)

const documentNumberElForm = (rule, value, callback) => {
  if (!documentNumber(value, form.cif)) {
    return callback(t('cif_not_valid'))
  }
  return callback()
}

const selectedCifCountry = computed(() => {
  for (const elem of countries) {
    const country = elem.options.find(
      (item) => item.iso_code === form.cif_country
    )
    if (country) {
      return country
    }
  }
  return null
})

const inputRules = reactive({
  business_name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  cif: [
    {
      validator: documentNumberElForm,
      trigger: ['blur', 'change']
    }
  ],
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_COMPANY, {
    business_name: form.business_name,
    cif: form.cif,
    cif_country: form.cif_country
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createCompany ?? null)
    loading.value = false
  }
}
</script>
