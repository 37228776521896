<template>
  <div class="signing-item" v-loading="loading">
    <div
      class="time-elapsed"
      v-if="!loading"
      :class="hasEndedDay ? 'ended' : ''"
    >
      <Icons class="clock" name="24px/time" /><span class="body2">{{
        timeElapsed
      }}</span>
    </div>
    <div class="signing-icon" v-if="!loading && !hasEndedDay">
      <!-- empleado no ha fichado aún en el día de hoy -->
      <Icons
        class="signing-svg"
        name="20px/play_circle"
        v-if="hasTimeControlStarted === false"
        @click="launchTimer"
      />
      <!-- empleado ha fichado y va a reanudar la jornada tras una pausa -->
      <Icons
        class="signing-svg"
        name="20px/play_circle"
        v-if="isEmployeeBreaking === true"
        @click="resumeTimer"
      />
      <!-- empleado ha fichado y va a finalizar su jornada -->
      <Icons
        class="signing-svg"
        name="20px/stop_circle"
        v-if="isEmployeeBreaking === false && hasTimeControlStarted === true"
        @click="finishTimer"
      />
      <!-- empleado ha fichado y va a pausar su jornada para un descanso -->
      <Icons
        class="signing-svg"
        name="20px/pause_circle"
        v-if="isEmployeeBreaking === false && hasTimeControlStarted === true"
        @click="
          $eventBus.$emit('showPauseDialog', {
            signingsToday,
            signingReasons,
            value
          })
        "
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { cloneDeep } from 'lodash'
import CREATE_SIGNING from '~/graphql/signings/mutation/createSigning.gql'
import UPDATE_SIGNING from '~/graphql/signings/mutation/updateSigning.gql'

export default defineComponent({
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const { $sentry, $eventBus } = useNuxtApp()
    return {
      $sentry,
      ...getFormInitialVariables()
    }
  },
  data() {
    return {
      loading: false,
      employee: null,
      interval: null,
      interval2: null,
      timeElapsed: null,
      signingReasons: null,
      showPauseDialog: false
    }
  },
  mounted() {
    if (this.value?.uuid) {
      this.getEmployee()
      this.getSigningReasons()
    }
    this.$eventBus.$on('signingNavbarRefetch', () => {
      this.$emit('refetch')
    })
  },
  computed: {
    signingsToday() {
      let signingsToday = this.employee?.working_day ?? []
      const itemIndex =
        signingsToday.length -
        1 -
        signingsToday
          .reverse()
          .findIndex(
            (i) => i.signingReason?.name_en === 'Start of the working day'
          )
      signingsToday = signingsToday
        .reverse()
        .slice(itemIndex, signingsToday.length)
        .map((j) => {
          return {
            uuid: j.uuid,
            signing_datetime: j.signing_datetime,
            is_signing_diff_day: !!(
              j.signin_datetime && moment().isAfter(j.signing_datetime, 'day')
            ),
            signout_datetime: j.signout_datetime,
            is_signout_diff_day: !!(
              j.signout_datetime && moment().isAfter(j.signout_datetime, 'day')
            ),
            signingReason: j.signingReason,
            signoutReason: j.signoutReason,
            time_elapsed: j.time_elapsed
          }
        })
      const lastSigning =
        signingsToday && signingsToday.length
          ? signingsToday[signingsToday.length - 1]
          : null
      if (
        lastSigning?.signing_datetime &&
        lastSigning?.signout_datetime &&
        lastSigning?.signoutReason?.name_en === 'End of the working day' &&
        getDuration(null, lastSigning.signout_datetime).asHours() > 8
      ) {
        signingsToday = []
      }
      return signingsToday
    },
    hasTimeControlStarted() {
      if (this.signingsToday?.length) {
        const lastSigning = this.signingsToday[this.signingsToday.length - 1]
        if (lastSigning.signoutReason?.name_en !== 'End of the working day') {
          return true
        }
        return false
      }
      return false
    },
    hasEndedDay() {
      if (this.signingsToday?.length) {
        const lastSigning = this.signingsToday[this.signingsToday.length - 1]
        if (
          lastSigning?.signoutReason?.name_en === 'End of the working day' &&
          lastSigning.signout_datetime &&
          moment().isSame(lastSigning.signout_datetime, 'day')
        ) {
          return true
        }
        return false
      }
      return false
    },
    isEmployeeBreaking() {
      if (this.signingsToday?.length) {
        const lastSigning = this.signingsToday[this.signingsToday.length - 1]
        if (
          lastSigning.signout_datetime &&
          lastSigning.signoutReason?.name_en !== 'End of the working day'
        ) {
          return true
        }
        return false
      }
      return false
    },
    duration() {
      const startTime = this.signingsToday.length
        ? this.signingsToday[0].signing_datetime
        : null
      const endTime =
        this.signingsToday?.length &&
        this.signingsToday[this.signingsToday.length - 1].signout_datetime
          ? this.signingsToday[this.signingsToday.length - 1].signout_datetime
          : null
      let timeElapsed
      if (!startTime && !endTime) {
        timeElapsed = '00:00:00'
      }
      if (startTime) {
        timeElapsed = getDurations(this.signingsToday)
        timeElapsed = getTimeElapsedFormat(timeElapsed)
      }

      return timeElapsed
    }
  },
  methods: {
    async getEmployee() {
      this.loading = true
      const employee = await apolloEmployeeForSigning({
        uuid: this.value.uuid
      })
      if (!employee || (employee && !employee.getEmployee)) {
        throw showError({
          statusCode: 404,
          message: 'Page not found',
          fatal: true
        })
      }
      this.loading = false
      this.employee = cloneDeep(employee.getEmployee)
      this.employee.working_day = this.employee.working_day.map((i) => {
        return {
          uuid: i.uuid,
          signingReason: i.signingReason,
          signing_datetime: i.signing_datetime
            ? moment.utc(i.signing_datetime).local()
            : null,
          signout_datetime: i.signout_datetime
            ? moment.utc(i.signout_datetime).local()
            : null,
          signoutReason: i.signoutReason,
          time_elapsed: i.time_elapsed
        }
      })
      this.handleSigningsChange(this.signingsToday)
    },
    async getSigningReasons(refetch = false) {
      try {
        this.signingReasons = await apolloSigningReasons(
          {
            sortBy:
              getAuthUser()?.value?.language?.toLowerCase() === 'en'
                ? 'name_en'
                : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                ? 'name_es'
                : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                ? 'name_fr'
                : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? 'name_de'
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                ? 'name_sv'
                : 'name_ar',
            sortDesc: false,
            search: '',
            page: 1,
            first: 100
          },
          this.filterExists,
          {},
          refetch ? 'network-only' : null
        )
      } catch (err) {
        this.$showError(err, this.t)
        this.$sentry(err, 'getSigningReasons')
      } finally {
      }
    },
    async launchTimer() {
      const launchReason =
        this.signingReasons?.getAllSigningReasons?.data?.find(
          (i) => i.name_en === 'Start of the working day'
        ) ?? null
      if (launchReason) {
        this.loading = true
        const response = await mutation(CREATE_SIGNING, {
          employee_uuid: this.value.uuid,
          signing_datetime: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          signing_reason_uuid: launchReason.uuid
        })
        if (response.error) {
          this.loading = false
          if (
            response.error.message.includes(
              'The signing cannot be created because the employee already has a signing in the date range provided'
            )
          ) {
            this.$showError(this.$t('signing_overlapped'))
          } else {
            this.$showError(response.error, this.t)
            this.$sentry(response.error, 'launchTimer')
          }
        }
        if (response.result) {
          this.loading = false
          this.$showSuccess(this.$t('launch_time_control_alert'))
          this.$emit('refetch')
        }
      }
    },
    async finishTimer() {
      const finishReason =
        this.signingReasons?.getAllSigningReasons?.data?.find(
          (i) => i.name_en === 'End of the working day'
        ) ?? null
      if (finishReason) {
        this.loading = true
        const response = await mutation(UPDATE_SIGNING, {
          uuid: this.signingsToday[this.signingsToday.length - 1].uuid,
          employee_uuid: this.value.uuid,
          signout_datetime: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          signout_reason_uuid: finishReason.uuid
        })
        if (response.error) {
          this.loading = false
          if (
            response.error.message.includes(
              'The signing cannot be created because the employee already has a signing in the date range provided'
            )
          ) {
            this.$showError(this.$t('signing_overlapped'))
          } else {
            this.$showError(response.error, this.t)
            this.$sentry(response.error, 'finishTimer')
          }
        }
        if (response.result) {
          this.loading = false
          this.$showSuccess(this.$t('finish_time_control_alert'))
          this.$emit('refetch')
        }
      }
    },
    async resumeTimer() {
      this.loading = true
      const response = await mutation(CREATE_SIGNING, {
        employee_uuid: this.value.uuid,
        signing_datetime: moment().utc().format('YYYY-MM-DD HH:mm:ss')
      })
      if (response.error) {
        this.loading = false
        if (
          response.error.message.includes(
            'The signing cannot be created because the employee already has a signing in the date range provided'
          )
        ) {
          this.$showError(this.$t('signing_overlapped'))
        } else {
          this.$showError(response.error, this.t)
          this.$sentry(response.error, 'launchTimer')
        }
      }
      if (response.result) {
        this.loading = false
        this.$showSuccess(this.$t('restart_time_control_alert'))
        this.$emit('refetch')
      }
    },
    handleSigningsChange(signings) {
      const startTime = signings.length ? signings[0].signing_datetime : null
      const endTime =
        signings.length && signings[signings.length - 1].signout_datetime
          ? signings[signings.length - 1].signout_datetime
          : null

      if (!startTime && !endTime) {
        this.stopInterval()
        this.timeElapsed = '00:00:00'
        return
      }

      if (startTime) {
        this.updateTimeElapsed(signings)
        if (endTime) {
          this.stopInterval()
        } else {
          this.startInterval(signings)
        }
      }
    },
    startInterval(signings) {
      this.stopInterval()
      this.interval = setInterval(() => {
        this.updateTimeElapsed(signings)
      }, 1000)
    },
    stopInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    updateTimeElapsed(signings) {
      const timeElapsed = getDurations(signings)
      this.timeElapsed = getTimeElapsedFormat(timeElapsed)
      this.$forceUpdate()
    }
  }
})
</script>

<style scope lang="scss">
.signing-item {
  width: 100%;
  position: relative;
  .time-elapsed {
    display: inline-block;
    .clock {
      display: inline-block;
      margin-right: 8px;
      position: relative !important;
      top: 0px !important;
    }
    .body2 {
      display: inline-block;
      position: relative;
      top: -6px;
    }

    &.ended {
      position: relative;
      top: 4px;
    }
  }

  .signing-icon {
    display: inline-block;
    @media (min-width: 1100px) {
      position: absolute;
      top: 5px;
      right: 0;
    }
    @media (max-width: 1100px) {
      position: absolute;
      top: 5px;
      right: 0;
    }

    i {
      margin-right: 0 !important;
      display: inline-block !important;

      &:nth-of-type(2) {
        position: relative !important;
        right: 0px !important;
        top: -2px;
        margin-left: 8px !important;
        @media (max-width: 1100px) {
          top: 0px !important;
        }
      }
    }
  }
}
</style>