export const invoiceStatuses = {
  colors: {
    pending: 'default',
    paid: 'success',
    partially_paid: 'success',
    cancelled: 'default',
    locked: 'danger'
  },
  statuses: [
    'pending',
    'paid',
    'partially_paid',
    'cancelled',
    'locked'
  ]
}
