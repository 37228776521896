<template>
  <div>
    <ElRow :gutter="0" justify="start" align="center" class="module-row">
      <ElCol
        v-for="(item, index) in modules"
        :key="index"
        :span="8"
        :xl="8"
        :lg="8"
        :sm="containerWidth > 700 ? 4 : 6"
        :md="containerWidth >= 1100 ? 8 : 4"
        align="center"
      >
        <UtilsModuleItem
          :module-selected="moduleSelected"
          :item="item"
          @setModule="$emit('setModule', $event)"
        />
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import verticalSidebarItemsBackoffice from '~/models/vertical-sidebar-items-backoffice'
import verticalSidebarItemsGuide from '~/models/vertical-sidebar-items-guide'

const props = defineProps({
  moduleSelected: {
    type: String,
    required: false,
    default: ''
  }
})

const { t } = useI18n()
const router = useRouter()
const { width } = useBreakpoints()
const roles = getAuthRoles()
const user = getAuthUser()
let verticalSidebarItems = verticalSidebarItemsBackoffice
if (!user.value?.employee) {
  verticalSidebarItems = verticalSidebarItems.filter(
    (i) => i.group !== '/profile'
  )
}
if (roles?.value?.find((i) => i.name === 'Guide')) {
  verticalSidebarItems = verticalSidebarItemsGuide
}

const modules = ref(
  verticalSidebarItems.filter((item) => {
    if (width.value > 1100) {
      return (
        item.icon &&
        item.title !== 'home' &&
        (!item.roles ||
          item.roles.length === 0 ||
          (item.roles && item.roles.length))
      )
    } else {
      return (
        item.icon &&
        (!item.roles ||
          item.roles.length === 0 ||
          (item.roles && item.roles.length))
      )
    }
  })
)
</script>

<style scoped lang="scss">
.module-row {
  @media (min-width: 1101px) {
    width: 318px !important;
    padding: 8px 16px 16px 16px !important;
  }
  display: flex;
  overflow: hidden !important;
  .el-col {
    margin: 8px 0 0 0px;
  }
}
</style>