//array of languages spoken in the location
export const locationLanguages = [
  {
    "name": "english",
    "flag": "/flags/united kingdom.svg",
  },
  {
    "name": "french",
    "flag": "/flags/france.svg",
  },
  {
    "name": "german",
    "flag": "/flags/germany.svg",
  },
  {
    "name": "italian",
    "flag": "/flags/italy.svg",
  },
  {
    "name": "spanish",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "albanian",
    "flag": "/flags/albania.svg",
  },
  {
    "name": "arabic",
    "flag": "/flags/saudi arabia.svg",
  },
  {
    "name": "aragonese",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "aranes",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "armenian",
    "flag": "/flags/armenia.svg",
  },
  {
    "name": "assamese",
    "flag": "/flags/india.svg",
  },
  {
    "name": "asturian",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "azerbaijani",
    "flag": "/flags/azerbaijan.svg",
  },
  {
    "name": "basque",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "belarusian",
    "flag": "/flags/belarus.svg",
  },
  {
    "name": "bengali",
    "flag": "/flags/bangladesh.svg",
  },
  {
    "name": "breton",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "bosnian",
    "flag": "/flags/bosnia.svg",
  },
  {
    "name": "bulgarian",
    "flag": "/flags/bulgaria.svg",
  },
  {
    "name": "catalan",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "chinese",
    "flag": "/flags/china.svg",
  },
  {
    "name": "corsican",
    "flag": "/flags/spain.svg",
  },
  {
    "name": "croatian",
    "flag": "/flags/croatia.svg",
  },
  {
    "name": "czech",
    "flag": "/flags/czech.svg",
  },
  {
    "name": "danish",
    "flag": "/flags/denmark.svg"
  },
  {
    "name": "dutch",
    "flag": "/flags/netherlands.svg"
  },
  {
    "name": "estonian",
    "flag": "/flags/estonia.svg"
  },
  {
    "name": "faroe",
    "flag": "/flags/fo.svg"
  },
  {
    "name": "finnish",
    "flag": "/flags/finland.svg"
  },
  {
    "name": "galician",
    "flag": "/flags/spain.svg"
  },
  {
    "name": "georgian",
    "flag": "/flags/georgia.svg"
  },
  {
    "name": "greek",
    "flag": "/flags/greece.svg"
  },
  {
    "name": "gujarati",
    "flag": "/flags/india.svg"
  },
  {
    "name": "hindi",
    "flag": "/flags/india.svg"
  },
  {
    "name": "hungarian",
    "flag": "/flags/hungary.svg"
  },
  {
    "name": "icelandic",
    "flag": "/flags/iceland.svg"
  },
  {
    "name": "irish",
    "flag": "/flags/ireland.svg"
  },
  {
    "name": "japanese",
    "flag": "/flags/japan.svg"
  },
  {
    "name": "kazakh",
    "flag": "/flags/kazakhstan.svg"
  },
  {
    "name": "korean",
    "flag": "/flags/south korea.svg"
  },
  {
    "name": "kyrgyz",
    "flag": "/flags/kyrgyzstan.svg"
  },
  {
    "name": "kannada",
    "flag": "/flags/india.svg"
  },
  {
    "name": "kashmiri",
    "flag": "/flags/india.svg"
  },
  {
    "name": "kurdish",
    "flag": "/flags/kuwait.svg"
  },
  {
    "name": "lithuanian",
    "flag": "/flags/lithuania.svg"
  },
  {
    "name": "latvian",
    "flag": "/flags/latvia.svg"
  },
  {
    "name": "macedonian",
    "flag": "/flags/macedonia.svg"
  },
  {
    "name": "marathi",
    "flag": "/flags/india.svg"
  },
  {
    "name": "mizo",
    "flag": "/flags/morocco.svg"
  },
  {
    "name": "mongolian",
    "flag": "/flags/mongolia.svg"
  },
  {
    "name": "malayalam",
    "flag": "/flags/india.svg"
  },
  {
    "name": "manipuri",
    "flag": "/flags/india.svg"
  },
  {
    "name": "montenegrin",
    "flag": "/flags/montenegro.svg"
  },
  {
    "name": "naga",
    "flag": "/flags/india.svg"
  },
  {
    "name": "norwegian",
    "flag": "/flags/norway.svg"
  },
  {
    "name": "nepali",
    "flag": "/flags/india.svg"
  },
  {
    "name": "oriya",
    "flag": "/flags/india.svg"
  },
  {
    "name": "punjabi",
    "flag": "/flags/india.svg"
  },
  {
    "name": "polish",
    "flag": "/flags/poland.svg"
  },
  {
    "name": "portuguese",
    "flag": "/flags/portugal.svg"
  },
  {
    "name": "punjabi",
    "flag": "/flags/india.svg"
  },
  {
    "name": "romanian",
    "flag": "/flags/romania.svg"
  },
  {
    "name": "russian",
    "flag": "/flags/russia.svg"
  },
  {
    "name": "sanskrit",
    "flag": "/flags/india.svg"
  },
  {
    "name": "scottish",
    "flag": "/flags/united kingdom.svg"
  },
  {
    "name": "serbian",
    "flag": "/flags/serbia.svg"
  },
  {
    "name": "sindhi",
    "flag": "/flags/india.svg"
  },
  {
    "name": "slovak",
    "flag": "/flags/slovakia.svg"
  },
  {
    "name": "slovenian",
    "flag": "/flags/slovenia.svg"
  },
  {
    "name": "slovenian",
    "flag": "/flags/slovenia.svg"
  },
  {
    "name": "sikkimese",
    "flag": "/flags/india.svg"
  },
  {
    "name": "swedish",
    "flag": "/flags/sweden.svg"
  },
  {
    "name": "tajik",
    "flag": "/flags/tajikistan.svg"
  },
  {
    "name": "tamil",
    "flag": "/flags/india.svg"
  },
  {
    "name": "telugu",
    "flag": "/flags/india.svg"
  },
  {
    "name": "turkish",
    "flag": "/flags/turkey.svg"
  },
  {
    "name": "turkmen",
    "flag": "/flags/turkmenistan.svg"
  },
  {
    "name": "tibetan",
    "flag": "/flags/india.svg"
  },
  {
    "name": "ukrainian",
    "flag": "/flags/ukraine.svg"
  },
  {
    "name": "urdu",
    "flag": "/flags/india.svg"
  },
  {
    "name": "uzbek",
    "flag": "/flags/india.svg"
  },
  {
    "name": "valencian",
    "flag": "/flags/spain.svg"
  },
  {
    "name": "welsh",
    "flag": "/flags/united kingdom.svg"
  }
]
