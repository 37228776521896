import ALL_COMPANIES from '@/graphql/companies/query/getAllCompanies.gql'

const companies = ref<{label: string; value: string}[]>([])
const issuers = ref<{label: string; value: string}[]>([])
const billables = ref<{label: string; value: string}[]>([])
const key1 = ref(0)
const key2 = ref(0)
const key3 = ref(0)
const firstTime = ref(true)
const firstTime2 = ref(true)
const firstTime3 = ref(true)

export function companiesAutocomplete(params: any) {
  const apolloQuery = ALL_COMPANIES
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'BUSINESS_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCompaniesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await companiesAutocomplete({ search: queryString })
    cb(results?.getAllCompanies?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCompaniesCb')
  }
}

export function getSearchCompanies(entityCompany: any, entityCompanies: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCompanies('', entityCompany, entityCompanies)
  }

  if (entityCompany && !companies?.value?.find((k: any) => k.value === entityCompany?.uuid)) {
    companies?.value?.push({
      label: `${entityCompany.business_name ?? ''}`,
      value: entityCompany.uuid
    })
  }

  return companies?.value ?? []
}

export function getSearchIssuers(entityCompany: any, entityCompanies: any) {
  if (firstTime2.value === true) {
    firstTime2.value = false
    searchIssuers('', entityCompany, entityCompanies)
  }

  if (entityCompany && !issuers?.value?.find((k: any) => k.value === entityCompany?.uuid)) {
    issuers?.value?.push({
      label: `${entityCompany.business_name ?? ''}`,
      value: entityCompany.uuid
    })
  }

  return issuers?.value ?? []
}

export function getSearchBillables(entityCompany: any, entityCompanies: any) {
  if (firstTime3.value === true) {
    firstTime3.value = false
    searchBillables('', entityCompany, entityCompanies)
  }

  if (entityCompany && !billables?.value?.find((k: any) => k.value === entityCompany?.uuid)) {
    billables?.value?.push({
      label: `${entityCompany.business_name ?? ''}`,
      value: entityCompany.uuid
    })
  }

  return billables?.value ?? []
}

function addRemoteEntityToArray(remoteArray: any, entityLocation: any, entityLocations: any, refresh = false) {
  const arrayToAdd: { label: any; value: any }[] = []
  entityLocations?.forEach((j: any) => {
    if (!remoteArray.find((k: any) => k.value === j.uuid)) {
      arrayToAdd.push({
        label: j.business_name,
        value: j.uuid
      })
    }
  })
  if (entityLocation && !remoteArray.find((k: any) => k.value === entityLocation.uuid)) {
    arrayToAdd.push({
      label: entityLocation.business_name,
      value: entityLocation.uuid
    })
  }
  if (refresh) {
    key1.value = 0
    key2.value = 0
    key3.value = 0
  }
  if (key1.value === 0) {
    key1.value = setCustomKeyVariable()
  }
  if (key2.value === 0) {
    key2.value = setCustomKeyVariable()
  }
  if (key3.value === 0) {
    key3.value = setCustomKeyVariable()
  }
  return [...remoteArray, ...arrayToAdd]
}

export async function searchCompanies(queryString: any, entityCompany: any, entityCompanies: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await companiesAutocomplete({ search: queryString })
    const remoteCompanies = results?.getAllCompanies?.data?.map((i: any) => {
      return {
        label: i.business_name,
        value: i.uuid
      }
    }) ?? []

    companies.value = addRemoteEntityToArray(remoteCompanies, entityCompany, entityCompanies, refresh)
    firstTime.value = false

    if (entityCompany) {
      getSearchCompanies(entityCompany, entityCompanies)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCompanies')
  }
}

export async function searchIssuers(queryString: any, entityCompany: any, entityCompanies: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await companiesAutocomplete({ search: queryString })
    const remoteIssuers = results?.getAllCompanies?.data?.map((i: any) => {
      return {
        label: i.business_name,
        value: i.uuid
      }
    }) ?? []
    issuers.value = addRemoteEntityToArray(remoteIssuers, entityCompany, entityCompanies, refresh)
    firstTime2.value = false

    if (entityCompany) {
      getSearchIssuers(entityCompany, entityCompanies)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchIssuers')
  }
}

export async function searchBillables(queryString: any, entityCompany: any, entityCompanies: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await companiesAutocomplete({ search: queryString })
    const remoteBillables = results?.getAllCompanies?.data?.map((i: any) => {
      return {
        label: i.business_name,
        value: i.uuid
      }
    }) ?? []
    billables.value = addRemoteEntityToArray(remoteBillables, entityCompany, entityCompanies, refresh)
    firstTime3.value = false

    if (entityCompany) {
      getSearchBillables(entityCompany, entityCompanies)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchBillables')
  }
}

export function getCompanyKey() {
  return key1.value
}

export function getIssuerKey() {
  return key2.value
}

export function getBillableKey() {
  return key3.value
}

export function initialCompanies() {
  companies.value = []
  key1.value = 0
  key2.value = 0
  key3.value = 0
  firstTime.value = true
  firstTime2.value = true
  firstTime3.value = true
}