export const categoryTypes = [
    {
      name: 'EXPERIENCE'
    },
    {
      name: 'ACCOMMODATION'
    },
    {
      name: 'RESTAURANT'
    },
    {
      name: 'LOCATION'
    },
    {
      name: 'ACTIVITY'
    },
    {
      name: 'TOUR'
    },
    {
      name: 'REEL'
    }
  ]
  