import { useI18n } from 'vue-i18n'
import { countries } from '@/models/countries'

export function getModelCountries() {
  const { t } = useI18n()
  const countriesArray = countries.map((country: { name: any; iso_code: any, phone_country: any, flag: any }) => {
    return {
      label: t(`countries.${country.name}`),
      value: country.name,
      iso_code: country.iso_code.toUpperCase(),
      phone_country: country.phone_country,
      flag: country.flag
    }
  })

  return [
    {
      options: countriesArray.slice(0, 5)
    },
    {
      options: countriesArray.slice(5, countriesArray.length).sort((a, b) => a.label.localeCompare(b.label))
    }
  ]
}

export function getListCountries() {
  const { t } = useI18n()
  const countriesArray = countries.map((country: { name: any; iso_code: any, phone_country: any, flag: any }) => {
    return {
      label: t(`countries.${country.name}`),
      value: country.name,
      iso_code: country.iso_code.toUpperCase(),
      phone_country: country.phone_country,
      flag: country.flag
    }
  })

  return countriesArray
}
