export default [
  {
    group: '/',
    icon: 'home',
    title: 'home',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'home',
        icon: 'home',
        to: '',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/profile',
    title: 'my_profile',
    icon: 'user',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'my_profile',
        icon: 'user',
        to: 'backoffice',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'calendar',
        icon: 'calendar',
        to: 'calendar',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'holidays',
        icon: 'vacaciones',
        to: 'holidays',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'signings',
        icon: 'fichajes',
        to: 'signings',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/experiences',
    title: 'experiences',
    icon: 'globe-2',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'experiences',
        icon: 'globe-2',
        to: 'experiences',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/itineraries',
    title: 'itineraries',
    icon: 'map-view',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'itineraries',
        icon: 'map-view',
        to: 'itineraries',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/locations',
    title: 'locations',
    icon: 'pin-2',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'locations',
        icon: 'pin-2',
        to: 'locations',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/restaurants',
    title: 'restaurants',
    icon: 'cutlery-2',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'restaurants',
        icon: 'cutlery-2',
        to: 'restaurants',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/accommodations',
    title: 'accommodations',
    icon: 'bed-3',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'accommodations',
        icon: 'bed-3',
        to: 'accommodations',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'accommodation_types',
        icon: 'bed-2',
        to: 'types',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'qualifications',
        icon: 'accommodation-qualifications',
        to: 'qualifications',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'room_types',
        icon: 'sunrise',
        to: 'room-types',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'meal_plans',
        icon: 'accommodation-meal-plans',
        to: 'meal-plans',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/activities',
    title: 'activities',
    icon: 'mouse',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'activities',
        icon: 'mouse',
        to: 'activities',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/routes',
    title: 'routes',
    icon: 'route',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'routes',
        icon: 'route',
        to: 'routes',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'transports',
        icon: 'truck',
        to: 'transports',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/bookings',
    title: 'bookings',
    icon: 'calendar-person',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'bookings',
        icon: 'calendar-person',
        to: 'bookings',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/guides',
    title: 'guides',
    icon: 'compass',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'guides',
        icon: 'compass',
        to: 'guides',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'tours',
        icon: 'globe-2',
        to: 'tours',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'reservations',
        icon: 'calendar-person',
        to: 'reservations',
        roles: ['Guide'],
        clickable: false
      }
    ]
  },
  {
    group: '/passengers',
    title: 'passengers',
    icon: 'shopping-bag-2',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'passengers',
        icon: 'shopping-bag-2',
        to: 'passengers',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'loyalty_cards',
        icon: 'cash',
        to: 'loyalty-cards',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/categories',
    title: 'categories',
    icon: 'modules',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'categories',
        icon: 'modules',
        to: 'categories',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'tags',
        icon: 'align-right-3',
        to: 'tags',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/memberships',
    title: 'memberships',
    icon: 'membresias',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'memberships',
        icon: 'membresias',
        to: 'memberships',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'services',
        icon: 'encuestas',
        to: 'services',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/finances',
    title: 'finances',
    icon: 'wallet',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'companies',
        icon: 'wallet',
        to: 'companies',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'customers',
        icon: 'clientes',
        to: 'customers',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'invoices',
        icon: 'file_text',
        to: 'invoices',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'serials',
        icon: 'border_left',
        to: 'serials',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'products',
        icon: 'producthunt',
        to: 'products',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'credit_cards',
        icon: 'credit_card',
        to: 'credit-cards',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'payments',
        icon: 'pos',
        to: 'payments',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'payment_methods',
        icon: 'wallet-plus',
        to: 'payment-methods',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/coupons',
    title: 'coupons',
    icon: 'coupon',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'coupons',
        icon: 'coupon',
        to: 'coupons',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  /* {
    group: '/human-resources',
    title: 'human_resources',
    icon: 'recursos-humanos',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'employees',
        icon: 'empleados',
        to: 'employees',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'departments',
        icon: 'departamentos',
        to: 'departments',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'positions',
        icon: 'employee-positions',
        to: 'positions',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'signing_reasons',
        icon: 'signing-reasons',
        to: 'signing-reasons',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'permits',
        icon: 'permisos',
        to: 'permits',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  }, */
  {
    group: '/community',
    title: 'community',
    icon: 'comments-2',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'community',
        icon: 'comments-2',
        to: 'community',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'suggestions',
        icon: 'sugerencias',
        to: 'suggestions',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'surveys',
        icon: 'encuestas',
        to: 'surveys',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/contact-us',
    title: 'contact_us',
    icon: 'support',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'contact_us',
        icon: 'support',
        to: 'contact-us',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  },
  {
    group: '/configuration',
    title: 'configuration',
    icon: '',
    roles: ['Admin', 'Backoffice'],
    clickable: true,
    children: [
      {
        title: 'users',
        icon: 'users',
        to: 'users',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'public_users',
        icon: 'user-arrow-tr',
        to: 'public-users',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'agents',
        icon: 'eye',
        to: 'agents',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      },
      {
        title: 'provinces',
        icon: 'globe-2',
        to: 'provinces',
        roles: ['Admin', 'Backoffice'],
        clickable: true
      }
    ]
  }
]
