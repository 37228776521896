<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="first_name" :label="$t('first_name')">
                <ElInput
                  v-model="form.first_name"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <ElFormItem prop="last_name" :label="$t('last_name')">
                <ElInput
                  v-model="form.last_name"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, numeric, minLength, maxLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import UPDATE_USER from '~/graphql/user/mutation/updateUser.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $firebaseApp, $sentry } = useNuxtApp()
const user = getAuthUser()
const countries = getModelCountries()

const form = reactive({
  first_name: user?.value?.first_name ?? '',
  last_name: user?.value?.last_name ?? ''
})

const emits = defineEmits(['submitUpdateProfileForm'])

const ruleFormRef = ref()
const loading = ref(false)

const rules = {
  first_name: { required },
  last_name: { required }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  first_name: [
    {
      required: true,
      message: t('field_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  last_name: [
    {
      required: true,
      message: t('field_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    updateUser()
  }
}

const updateUser = async () => {
  loading.value = true
  const response = await mutation(UPDATE_USER, {
    uuid: user.value.uuid,
    first_name: form.first_name,
    last_name: form.last_name
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'update')
  }
  if (response?.result?.data?.updateUser?.uuid) {
    $showSuccess(t('entity_has_been_updated_successfully'))
    v$.value.$reset()
    emits('submitUpdateProfileForm')
    loading.value = false
  }
}
</script>
