import ALL_SERIALS from '@/graphql/serials/query/getAllSerialsAutocomplete.gql'

const serials = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function serialsAutocomplete(params: any) {
  const apolloQuery = ALL_SERIALS
  const variables = {
    search: params.search ? params.search : null,
    orderBy: [
      {
        column: 'PREFIX',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchSerialsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await serialsAutocomplete({ search: queryString })
    cb(results?.getAllSerials?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchSerialsCb')
  }
}

export function getSearchSerials(entitySerial: any, entitySerials: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchSerials('', entitySerial, entitySerials)
  }

  if (entitySerial && !serials?.value?.find((item) => item.value === entitySerial?.uuid)) {
    serials.value.push({
      label: entitySerial.prefix,
      value: entitySerial.uuid
    })
  }

  return serials?.value ?? []
}

export async function searchSerials(queryString: any, entitySerial: any, entitySerials: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await serialsAutocomplete({ search: queryString })
    const remoteSerials = results?.getAllSerials?.data?.map((i: any) => {
      return {
        label: i.prefix,
        value: i.uuid
      }
    }) ?? []
    const serialsToAdd: { label: any; value: any }[] = []
    entitySerials?.forEach((j: any) => {
      if (!remoteSerials.find((k: any) => k.value === j.uuid)) {
        serialsToAdd.push({
          label: j.prefix,
          value: j.uuid
        })
      }
    })
    if (entitySerial && !remoteSerials.find((k: any) => k.value === entitySerial.uuid)) {
      serialsToAdd.push({
        label: entitySerial.prefix,
        value: entitySerial.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    serials.value = [...remoteSerials, ...serialsToAdd]
    firstTime.value = false

    if (entitySerial) {
      getSearchSerials(entitySerial, entitySerials)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchSerials')
  }
}

export function getSerialKey () {
  return key.value
}

export function initialSerials () {
  serials.value = []
  firstTime.value = true
  key.value = 0
}