import CREATE_COUNTRY from '~/graphql/countries/mutation/createCountry.gql'

export async function mutationCountry(variables: any, t: any) {
    const { $showError, $sentry } = useNuxtApp()
    const response = await mutation(CREATE_COUNTRY, variables)
    if (response.error) {
        if (response.error.message.includes('input.name') || response.error.message.includes('input.code')) {
            // $showError(t('country_already_exists')
        } else {
            $showError(response.error, t)
            $sentry(response.error, 'mutationCountry')
        }
        return null
    }
    if (response.result) {
        return response.result?.data?.createCountry ?? null
    }
}