<template>
  <div>
    <div v-if="!showLanguagesMenu">
      <ElAvatar
        :src="
          user && user.avatar && user.avatar.encode_file
            ? user.avatar.encode_file
            : ''
        "
        class="inline-block body1-strong"
        :size="42"
      >
        <span class="avatar-initials">{{ initials }}</span>
      </ElAvatar>
      <div class="avatar-name">
        <span
          v-if="user && user.first_name && user.last_name"
          class="dashboard-title"
        >
          {{ user.first_name }} {{ user.last_name }}
        </span>
      </div>
      <div v-for="(item, key) in menuItems" :key="key">
        <div
          v-if="item.title"
          class="item-list"
          :class="item.class"
          @click="
            item.action
              ? $emit('click', { action: item.action, value: item.value })
              : item.class === 'language'
              ? (showLanguagesMenu = true)
              : (showLanguagesMenu = false)
          "
        >
          <Icons :name="item.icon" />
          <span class="body1-strong" :class="item.class"
            >{{ item.title
            }}<span v-if="item.image" style="position: relative; top: 1px">
              - <img :src="item.image" /></span
          ></span>
          <Icons v-if="item.rightIcon" :name="item.rightIcon" />
        </div>
        <div
          v-else-if="!item.title && user?.employee"
          class="item-list"
          :class="item.class"
        >
          <FullLayoutSigningItem
            :key="customSigningKey"
            :value="user.employee"
            @refetch="customSigningKey = setCustomKeyVariable()"
          />
        </div>
      </div>
    </div>
    <div v-if="showLanguagesMenu">
      <div @click="showLanguagesMenu = false" class="language-back">
        <Icons name="24px/chevron_left" />
        <div class="language-back-item">
          <span class="body1-strong">{{ t('back') }}</span>
        </div>
      </div>
      <div v-for="(item, key) in languages" :key="key" class="language-item">
        <div
          @click="
            $emit('click', {
              action: 'set_locale',
              value: item.iso_code
            }),
              (showLanguagesMenu = false)
          "
        >
          <img :src="item.flag" />&nbsp;-&nbsp;
          <span class="body2-strong">{{ item.label.split(' - ')[1] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const user = getAuthUser()
const { t } = useI18n()
const languages = getLanguages()
const showLanguagesMenu = ref(false)
const customSigningKey = ref(0)

const props = defineProps({
  user: {
    type: Object,
    required: false,
    default: () => {}
  }
})

onMounted(() => {
  showLanguagesMenu.value = false
})

const initials = computed(() => {
  return props.user && props.user.first_name && props.user.last_name
    ? `${props.user.first_name
        .substring(0, 1)
        .toUpperCase()} ${props.user.last_name.substring(0, 1).toUpperCase()}`
    : ''
})

const selectedLanguage = computed(() => {
  return user?.value?.language
    ? languages?.find(
        (i) => i.iso_code.toLowerCase() === user.value.language.toLowerCase()
      )?.flag ?? '/flags/united kingdom.svg'
    : '/flags/united kingdom.svg'
})

const menuItems = ref([
  {
    title: '',
    icon: '',
    children: [],
    class: 'signing',
    divider: true
  },
  {
    title: t('settings'),
    icon: '24px/gear',
    action: 'go_settings',
    value: null,
    children: []
  },
  {
    title: `${t('language')}`,
    image: selectedLanguage.value,
    icon: '24px/globe',
    class: 'language',
    value: null,
    children: [],
    rightIcon: '24px/chevron_right'
  },
  {
    title: t('update_password'),
    icon: '24px/lock_2',
    action: 'update_password',
    value: null,
    children: []
  },
  {
    title: t('logout'),
    icon: '24px/log_out_3',
    action: 'logout',
    class: 'logout',
    value: null,
    children: []
  }
])
</script>
<style scoped lang="scss">
.el-avatar {
  background-color: var(--brand-blue) !important;
  margin-right: 12px;
  display: inline-block;
  .avatar-initials {
    position: relative;
    top: 9px;
    left: 1px;
  }
}

.avatar-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  display: inline-block;
  max-width: calc(100% - 12px - 42px - 32px) !important;
  position: relative;
  top: -4px;
}

.item-list {
  padding: 11px 0px;
  height: 42px;

  &:first-of-type {
    margin-top: 4px;
  }

  &:nth-of-type(2) {
    margin-bottom: 4px;
  }

  &.logout {
    margin-top: calc(100vh - 290px);
    position: relative;
    top: 14px;
  }

  i {
    display: inline-block;

    &:nth-of-type(1) {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }

    &:nth-of-type(2) {
      position: absolute;
      right: 24px;
    }
  }

  span {
    position: relative;
    top: -4px;
  }

  img {
    height: 24px;
    width: 24px;
    display: inline-block;
    position: relative;
    top: -1px;
  }
}

.language-back {
  border-bottom: 1px solid var(--brand-off-white);
  margin-bottom: 4px;
  height: 40px;
  i {
    display: inline-block !important;
    position: relative;
    top: 6px !important;
    margin-right: 8px;
  }

  div.language-back-item {
    display: inline-block !important;
  }
}

.language-item {
  padding: 11px 0px;
  height: 42px;
  &:last-of-type {
    margin-bottom: 4px;
  }

  i {
    display: inline-block;

    &:nth-of-type(1) {
      margin-right: 8px;
      position: relative;
      top: -2px;
    }

    &:nth-of-type(2) {
      position: absolute;
      right: 0;
    }
  }
  img {
    height: 24px;
    width: 24px;
    display: inline-block;
    margin-right: 2px;
  }
}
</style>