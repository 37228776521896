export const locationSubtypes = [
  {
    name: 'AIRPORT'
  },
  {
    name: 'MUSEUM'
  },
  {
    name: 'SPORT'
  },
  {
    name: 'MONUMENT'
  }
]
