import {
  generateFilterQuery
} from '~/utils/graphqlDynamicQuery'

export function generateQuery (
  queryName: string,
  orderBy: any,
  searchField: any,
  filterSaved = null,
  subSelection: any = null
) {
  return generateFilterQuery(
    queryName,
    filterSaved,
    orderBy,
    searchField,
    subSelection
  )
}
