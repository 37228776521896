import { useI18n } from 'vue-i18n'
import { languages } from '@/models/languages'

export function getLanguages () {
  const { t } = useI18n()
  return languages.map((language: { name: any; iso_code: any, flag: any }) => {
    return {
      label: `${language.iso_code.toUpperCase()} - ${t(`languages.${language.name}`)}`,
      iso_code: language.iso_code.toUpperCase(),
      name: language.name,
      flag: language.flag
    }
  })
}
