<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          require-asterisk-position="right"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="old_password" :label="$t('current_password')">
                <ElInput
                  v-model="form.old_password"
                  :type="showOldPassword ? 'text' : 'password'"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #suffix>
                    <Icons
                      v-if="!showOldPassword"
                      name="16px/eye"
                      @click="showOldPassword = true"
                    />
                    <Icons
                      v-else
                      name="16px/eye_no"
                      @click="showOldPassword = false"
                    />
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="password" :label="$t('password')">
                <ElInput
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #suffix>
                    <Icons
                      v-if="!showPassword"
                      name="16px/eye"
                      @click="showPassword = true"
                    />
                    <Icons
                      v-else
                      name="16px/eye_no"
                      @click="showPassword = false"
                    />
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="password_confirmation"
                :label="$t('password_confirmation')"
              >
                <ElInput
                  v-model="form.password_confirmation"
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #suffix>
                    <Icons
                      v-if="!showPasswordConfirmation"
                      name="16px/eye"
                      @click="showPasswordConfirmation = true"
                    />
                    <Icons
                      v-else
                      name="16px/eye_no"
                      @click="showPasswordConfirmation = false"
                    />
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancelUpdatePasswordForm')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import UPDATE_PASSWORD from '~/graphql/auth/mutation/updatePassword.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()
const { width } = useBreakpoints()

const form = reactive({
  old_password: '',
  password: '',
  password_confirmation: ''
})

const emits = defineEmits(['submitUpdatePasswordForm'])

const ruleFormRef = ref()
const loading = ref(false)
const showOldPassword = ref(false)
const showPassword = ref(false)
const showPasswordConfirmation = ref(false)

const passwordIsSame = (password) => {
  return password === form.password
}

const passwordIsNotSame = (password) => {
  return password !== form.old_password
}

const rules = {
  old_password: { required },
  password: { required, minLength: minLength(8) },
  password_confirmation: {
    required,
    minLength: minLength(8),
    sameAsPassword: passwordIsSame,
    notSameAsOldPassword: passwordIsNotSame
  }
}

const v$ = useVuelidate(rules, form)

const validatePasswordConfirmation = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Please input the password again'))
  } else if (value !== form.password) {
    callback(new Error("Two inputs don't match!"))
  } else {
    callback()
  }
}

const validateNotPasswordConfirmation = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Please input the password again'))
  } else if (value === form.old_password) {
    callback(new Error('Two inputs must not match!'))
  } else {
    callback()
  }
}

const inputRules = reactive({
  old_password: [
    {
      required: true,
      message: t('current_password_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  password: [
    {
      required: true,
      message: t('password_is_required'),
      trigger: ['blur', 'change']
    },
    {
      min: 8,
      message: t('password_min_length'),
      trigger: ['blur', 'change']
    }
  ],
  password_confirmation: [
    {
      required: true,
      message: t('password_is_required'),
      trigger: ['blur', 'change']
    },
    {
      min: 8,
      message: t('password_min_length'),
      trigger: ['blur', 'change']
    },
    {
      validator: validatePasswordConfirmation,
      message: t('password_same'),
      trigger: ['blur', 'change']
    },
    {
      validator: validateNotPasswordConfirmation,
      message: t('password_are_the_same'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    //
  } else {
    updatePassword()
  }
}

const updatePassword = async () => {
  loading.value = true
  const response = await mutation(UPDATE_PASSWORD, { input: form })
  if (response.error) {
    loading.value = false
    if (['Current password is incorrect'].includes(response.error.message)) {
      $showError(t('current_password_is_incorrect'))
    } else if (
      ['The new password cannot be the same as the old password'].includes(
        response.error.message
      )
    ) {
      $showError(t('password_are_the_same'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'updatePassword')
    }
  }
  if (response.result) {
    $showSuccess(response.result.data.updatePassword.message)
    $cookies.remove('apollo-token')
    $cookies.remove('apollo-token-refresh')
    v$.value.$reset()
    emits('submitUpdatePasswordForm')
    loading.value = false
    window.location.href = '/login'
  }
}
</script>
