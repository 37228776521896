import axios from 'axios'
import { Buffer } from 'buffer'

export function getFile(url: string, mimeType: string) {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "*",
                    'Authorization': `Bearer ${$cookies.get('apollo-token')}`
                },
                responseType: 'arraybuffer'
            }
            const response = await axios.request(config)
            resolve(`data:${mimeType};base64,${Buffer.from(response.data, 'binary').toString("base64")}`);
        } catch (error) {
            reject(error)
        }
    });
}

export function blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function base64ToBlob(base64: string, mimeType: string) {
    var str =
        base64.replace(`data:${mimeType};base64,`, '')
    var byteCharacters = window.atob(str);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: mimeType });

    const file = new File([blob], "file", {
        type: mimeType
    });

    return file
}

export function downloadFile(fileBase64: string, fileName: string, mimeType: string) {
    const element = document.createElement('a');
    element.setAttribute('href', fileBase64);
    element.setAttribute('download', `${fileName}.${mimeType}`);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}