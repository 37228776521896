import { useI18n } from 'vue-i18n';
import { categoryTypes } from '~/models/category-types';

export const getCategoryTypes = () => {
  const { t } = useI18n();
  return categoryTypes.map((item) => {
    return {
      label: t(item.name.toLowerCase()),
      value: item.name
    };
  });
}
