<template>
  <div class="heading">
    <ElInput
      v-if="showSearch"
      v-model="search"
      class="input-search"
      :placeholder="searchPlaceholder ? searchPlaceholder : $t('search')"
      @input="debounce(() => $eventBus.$emit('termTab', search))"
    >
      <template #prefix>
        <Icons name="20px/search" />
      </template>
    </ElInput>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const route = useRoute()
    const { $eventBus } = useNuxtApp()
    const { width, height } = useBreakpoints()
    const { debounce } = getTableInitialVariables()
    return {
      $eventBus,
      route,
      width,
      debounce
    }
  },
  data() {
    return {
      routeFullPath: '',
      showSearch: true,
      search: '',
      searchPlaceholder: ''
    }
  },
  created() {
    this.$eventBus.$on('showSearch', ($event) => {
      this.showSearch = $event
    })
    this.$eventBus.$on('searchPlaceholder', ($event) => {
      this.searchPlaceholder = $event
      this.showSearch = true
    })
  },
  methods: {
    normalizeRoute(route) {
      const lastChar = route.substr(route.length - 1)
      if (lastChar === '/') {
        route = route.slice(0, -1)
      }
      return route
    }
  }
})
</script>

<style scoped lang="scss">
.heading {
  @media (min-width: 1100px) {
    .input-search {
      width: 360px !important;
      height: 36px !important;
      position: relative;
      top: 13px;
      :deep(.el-input__wrapper) {
        height: 36px !important;
      }
    }
  }
  @media (max-width: 1100px) {
    .input-search {
      width: calc(100% - 24px - 24px) !important;
      height: 36px !important;
      :deep(.el-input__wrapper) {
        height: 36px !important;
      }
    }
  }
}
</style>