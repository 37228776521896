export const languages = [
  {
    name: 'spanish',
    iso_code: 'ES',
    flag: '/flags/spain.svg'
  },
  {
    name: 'english',
    iso_code: 'EN',
    flag: '/flags/united kingdom.svg'
  },
  {
    name: 'french',
    iso_code: 'FR',
    flag: '/flags/france.svg'
  },
  {
    name: 'german',
    iso_code: 'DE',
    flag: '/flags/germany.svg'
  },
  {
    name: 'swedish',
    iso_code: 'SV',
    flag: '/flags/sweden.svg'
  },
  {
    name: 'arabic',
    iso_code: 'AR',
    flag: '/flags/saudi arabia.svg'
  }
]
