export const serialTypes = {
    colors: {
        sale_invoice: 'primary',
        credit_invoice: 'primary',
        purchase_invoice: 'primary'
    },
    types: [
        'sale_invoice',
        'credit_invoice',
        'purchase_invoice'
    ]
}
