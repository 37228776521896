import { useI18n } from 'vue-i18n';
import { locationLanguages } from '~/models/location-languages';

export const getLocationLanguages = () => {
  const { t } = useI18n();
  const languages = locationLanguages.map((locationLanguage) => {
    return {
      label: t(`languages.${locationLanguage.name}`),
      value: locationLanguage.name,
      flag: locationLanguage.flag
    };
  });
  return [
    {
      options: languages.slice(0, 5)
    },
    {
      options: languages.slice(5, languages.length)
    }
  ]
}
